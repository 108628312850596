import {
  collection,
  deleteDoc,
  doc,
  FirestoreError,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore'
import {httpsCallable, HttpsCallableResult} from 'firebase/functions'
import {
  FullMetadata,
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytes,
  uploadBytesResumable,
  UploadTask
} from 'firebase/storage'

import {TEvaluation, TSpeech, TSpeechThumbnails} from '../types/types'
import Logger from '../utils/Logger'
import MimeTypes from '../utils/MimeTypes'
import {db, functions, storage} from './firebase'

export async function getSpeeches(): Promise<TSpeech[]> {
  return getDocs(collection(db, 'speeches')).then(snapshot =>
    snapshot.docs.map(doc => doc.data() as TSpeech)
  )
}

export async function getSpeech(id: string): Promise<TSpeech> {
  const ref = doc(db, 'speeches', id)
  return getDoc(ref).then(doc => doc.data() as TSpeech)
}

export async function postSpeech(
  speech: Partial<TSpeech>
): Promise<string | void> {
  const ref = doc(collection(db, 'speeches'))

  return setDoc(ref, {
    ...speech,
    id: ref.id,
    date: serverTimestamp()
  })
    .then(() => {
      Logger.debug(`Speech has been added with id: ${ref.id}`)
      return ref.id
    })
    .catch((error: FirestoreError) => {
      Logger.error(`Error adding speech with id: ${ref.id}`, error)
    })
}

export function uploadSpeech({
  file,
  filename,
  id
}: {
  file: Blob | Uint8Array | ArrayBuffer
  filename: string
  id: string
}): UploadTask {
  Logger.debug('uploadSpeech called. File:', file, 'filename:', filename)

  const storageRef = ref(storage, `speeches/${id}/${filename}`)
  return uploadBytesResumable(storageRef, file)
}

/**
 * Uploads data urls to Firebase Storage and returns an array of download URLs.
 */
export async function uploadThumbnails({
  thumbnails,
  id
}: {
  thumbnails: TSpeechThumbnails
  id: string
}): Promise<string[]> {
  Logger.debug('uploadThumbnails called.')

  const promises: Promise<string>[] = thumbnails.map(async (file, index) => {
    const blob = await createBlobFromUri(file.url)

    const contentType: string = blob.type
    const metadata = {
      contentType
    }
    const fileExtension = MimeTypes.getFileExtension(contentType)

    const storageRef = ref(
      storage,
      `speeches/${id}/thumbnails/${index}.${fileExtension}`
    )

    Logger.debug(`Starting upload of thumbnail: ${storageRef.name}`)
    return getDownloadURL((await uploadBytes(storageRef, blob, metadata)).ref)
  })
  return Promise.all(promises)
}

export async function patchSpeech(
  id: string,
  speech: Partial<TSpeech>
): Promise<void> {
  const ref = doc(db, 'speeches', id)
  return updateDoc(ref, speech)
}

export async function deleteSpeech(id: string): Promise<void> {
  const ref = doc(db, 'speeches', id)

  return deleteDoc(ref)
    .then(() => {
      Logger.debug(`Speech with id ${id} successfully deleted.`)
    })
    .catch(error => {
      Logger.error('Error removing document: ', error)
    })
}

// TODO: implement this.
// function deleteSpeechVideo(ref: string) {}

export async function getSpeechVideoMetadata(
  url: string
): Promise<FullMetadata> {
  const storageRef = ref(storage, url)
  return getMetadata(storageRef)
}

// TODO: Maybe extract this to a FileHandler/PickerClass
function createBlobFromUri(uri: string): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = function () {
      resolve(xhr.response)
    }
    xhr.onerror = function (e) {
      console.error(e)
      reject(new Error('Network request failed'))
    }
    xhr.responseType = 'blob'
    xhr.open('GET', uri, true)
    xhr.send(null)
  })
}

export async function getSpeechesByUser(userId: string): Promise<TSpeech[]> {
  const q = query(
    collection(db, 'speeches'),
    where('userId', '==', userId),
    orderBy('date', 'desc')
  )

  return getDocs(q).then(snapshot =>
    snapshot.docs.map(doc => doc.data() as TSpeech)
  )
}

export async function getSpeechesByEvaluator(
  userId: string
): Promise<TSpeech[]> {
  const q = query(
    collection(db, 'evaluations'),
    where('evaluator.id', '==', userId)
  )
  const speechIds: string[] = await getDocs(q).then(snapshot =>
    snapshot.docs.map(doc => doc.data() as TEvaluation).map(e => e.speechId)
  )

  const q2 = query(collection(db, 'speeches'), where('id', 'in', speechIds))
  return getDocs(q2).then(snapshot =>
    snapshot.docs.map(doc => doc.data() as TSpeech)
  )
}

const _countSpeechView = httpsCallable(functions, 'incrementSpeechView')

export async function countSpeechView(
  id: string
): Promise<HttpsCallableResult<unknown>> {
  return _countSpeechView({
    id
  })
}
