import {useCallback, useState} from 'react'

export default function useModal(): {
  isOpen: boolean
  showModal: () => void
  closeModal: () => void
} {
  const [isOpen, setOpen] = useState(false)

  const showModal = useCallback(() => setOpen(true), [])
  const closeModal = useCallback(() => setOpen(false), [])

  return {isOpen, showModal, closeModal}
}
