import {Ionicons} from '@expo/vector-icons'
import {
  Column,
  Divider,
  Heading,
  Icon,
  IconButton,
  Row,
  Text
} from 'native-base'
import React, {useCallback} from 'react'

import LOCAL from '../../misc/localisation'
import {
  TNotificationReasons,
  TNotificationSettings,
  TNotificationMedia
} from '../../types/types'

const defaultSettings: TNotificationSettings = {
  'new-evaluation': {mail: true, push: true},
  'updated-evaluation': {mail: true, push: true},
  'new-speech-by-followee': {mail: false, push: false},
  'new-follower': {mail: false, push: false},
  comment: {mail: false, push: false},
  like: {mail: false, push: false}
}

// TODO: Reactivate notification settings as soon as they are implemented.
// const settingKeys = Object.keys(defaultSettings)
const settingKeys: (keyof TNotificationSettings)[] = [
  'new-evaluation',
  'updated-evaluation'
]

export default function NotificationSettings({
  settings = defaultSettings,
  update
}: {
  settings: TNotificationSettings | undefined
  update: (settings: TNotificationSettings) => void
}) {
  const toggleNotification = useCallback(
    (reason: TNotificationReasons, medium: TNotificationMedia) =>
      update({
        ...settings,
        [reason]: {...settings[reason], [medium]: !settings[reason][medium]}
      }),
    [settings, update]
  )

  return (
    <Column w='full'>
      <Heading size='md'>{LOCAL.SETTINGS.NOTIFICATIONS.NOTIFICATIONS}</Heading>
      <Divider bg='muted.250' thickness='2' my='2' />
      <Column space='sm'>
        {settingKeys.map((reason: string) => {
          return (
            <Row
              w='full'
              key={reason}
              justifyContent='space-between'
              alignItems='center'
            >
              <Text>
                {
                  LOCAL.SETTINGS.NOTIFICATIONS.REASONS[
                    reason as TNotificationReasons
                  ]
                }
              </Text>
              <Row space='xs'>
                {/* TODO: Reactivate this as soon as push notifications are implemented. */}
                {/* <IconButton
                  icon={<Icon as={FontAwesome} name='mobile' />}
                     onPress={() =>
                    toggleNotification(reason as TNotificationReasons, 'push')
                  }
                  p='0'
                  isPressed={settings[reason as TNotificationReasons].push}
                  _pressed={{
                    bg: 'none',
                    _icon: {
                      color: 'primary.500'
                    }
                  }}
                  _hover={{
                    bg: 'none',
                    _icon: {
                      color: 'primary.700'
                    }
                  }}
                /> */}
                <IconButton
                  icon={<Icon as={Ionicons} name='mail' />}
                  onPressOut={() =>
                    toggleNotification(reason as TNotificationReasons, 'mail')
                  }
                  isDisabled={false}
                  p='0'
                  isPressed={settings[reason as TNotificationReasons].mail}
                  _pressed={{
                    bg: 'none',
                    _icon: {
                      color: 'primary.500'
                    }
                  }}
                  _hover={{
                    bg: 'none',
                    _icon: {
                      color: 'primary.700'
                    }
                  }}
                />
              </Row>
            </Row>
          )
        })}
      </Column>
    </Column>
  )
}
