import {extendTheme, themeTools} from 'native-base'
const colorBaseStyle = (props: any) => {
  return {
    color: themeTools.mode('darkText', 'lightText')(props)
  }
}

export default extendTheme({
  components: {
    IconButton: {
      _icon: {
        baseStyle: colorBaseStyle
      }
    },
    Icon: {
      baseStyle: colorBaseStyle
    },
    Text: {
      baseStyle: colorBaseStyle
    },
    Heading: {
      baseStyle: colorBaseStyle
    }
  }
})
