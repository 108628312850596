import {Heading, Text} from 'native-base'

export default function Terms() {
  return (
    <>
      <Heading>AGB</Heading>

      <Heading mt='4' size='md'>
        § 1 Geltungsbereich
      </Heading>
      <Text>
        Diese Allgemeinen Geschäftsbedingungen gelten für sämtliche zwischen der
        Schilling & Deol GbR, Jütlandring 11, 22419 Hamburg (nachfolgend
        "Zpeeky" genannt) und dem Nutzer von Zpeeky Services (nachfolgend
        „Nutzer“) im Rahmen der Nutzung der Zpeeky-Plattform (nachfolgend
        „Datenbank“) entstehenden Rechtsverhältnisse. Es gilt außerdem unsere
        Datenschutzbelehrung. Für gewerbliche Nutzer gelten ebenso diese AGB.
        Gesetzliche Bestimmungen für gewerbliche Nutzer gelten, sofern diese
        nicht explizit anderweitig kommuniziert sind.
      </Text>

      <Heading mt='4' size='md'>
        § 2 Leistungen von Zpeeky
      </Heading>
      <Text>
        (1) In der von Zpeeky betriebenen Datenbank sind rhetorische Videos zu
        Lernzwecken, Nutzerprofile, Bewertungen und Lernkurse gespeichert.
        Zpeeky gewährt ihren Nutzern über die Funktion „Suchen“ den
        Online-Zugriff auf die in der Datenbank gespeicherten Inhalte, sowie
        über die Funktion „Inserat hinzufügen“ die Einstellung eigener Inhalte
        (nachfolgend „Inserate“) in die Datenbank. Zpeeky ist nicht Vermittler,
        nicht Käufer oder Verkäufer und nicht Vertreter des Redners oder
        Redebewerters der in der Datenbank gespeicherten Inserate, sondern
        lediglich technischer Dienstleister. (2) Die Ermöglichung des Zugriffs
        bzw. des Einstellens von Inhalten in die Datenbank liegt im Ermessen von
        Zpeeky. Ein Anspruch des Nutzers auf Beibehaltung bestimmter Leistungen
        oder Teilen davon besteht nicht. (3) Für die Nutzung eines Kurses können
        Kosten anfallen. Dies ist in jedem Fall explizit ausgewiesen mit
        Mehrwertsteuer ausgewiesen.
      </Text>
      <Heading mt='4' size='md'>
        § 3 Registrierung durch den Nutzer
      </Heading>
      <Text>
        (1) Die Verwendung bestimmter Services von Zpeeky setzt die
        Registrierung des Nutzers auf der Website voraus. Die Registrierung ist
        nur volljährigen und voll geschäftsfähigen Nutzern erlaubt. Nur
        registrierten Nutzern ist es möglich, Inserate in die Datenbank
        einzustellen. (2) Bei der Registrierung hat der Nutzer seinen Namen,
        seine Anschrift, seine Telefonnummer, seine E-Mail-Adresse, weitere
        persönliche Daten sowie ein Passwort anzugeben. Der Nutzer hat die
        Angaben auf dem Registrierungsformular wahrheitsgemäß zu machen und bei
        Änderungen die Angaben unverzüglich zu aktualisieren. Jeder Nutzer darf
        sich nur einmal bei Zpeeky registrieren. Registrierungen sind nicht
        übertragbar und dürfen nur von dem registrierten Nutzer verwendet
        werden. (3) Die E-Mail-Adresse und das Passwort sind die Zugangsdaten
        für die Nutzung der registrierungspflichtigen Services von Zpeeky. Der
        Nutzer hat das Passwort geheim zu halten und darf dieses nicht an Dritte
        weitergeben. Sollten Dritte von dem Passwort des Nutzers Kenntnis
        erlangen, so hat der Nutzer dieses umgehend an Zpeeky zu melden und das
        Passwort zu ändern. (4) Zpeeky behält sich vor, Registrierungen, bei
        welchen sich der Nutzer länger als 12 Monate nicht angemeldet hat und
        einen kostenlosen Account besitzt, zu löschen. Zahlende Mitglieder sind
        davon nicht betroffen. (5) Für die bei der Registrierung erhobenen
        personenbezogenen Daten des Nutzers gilt unsere Datenschutzbelehrung.
      </Text>
      <Heading mt='4' size='md'>
        § 4 Einstellung von Inhalten in die Datenbank
      </Heading>
      <Text>
        (1) Mit der Übermittlung der Registrierungsdaten und der zur Einstellung
        in die Datenbank vorgesehenen Inhalte gibt der Nutzer gegenüber Zpeeky
        ein Angebot über die zeitlich begrenzte Aufnahme und Bereitstellung des
        Inhalts in der Datenbank ab. Zpeeky entscheidet nach Zugang des Angebots
        über dessen Annahme. Die Annahme des Angebots erfolgt durch die Aufnahme
        des Inhalts in die Datenbank. (2) Der Nutzer darf die dem Paket
        entsprechende Anzahl an Inseraten zeitgleich einstellen. Hält der Nutzer
        mehr Inserate gleichzeitig in der Datenbank zum Abruf bereit, berechtigt
        dies Zpeeky zur Löschung der Inserate. Eine Umgehung dieser Regelung
        berechtigt Zpeeky zur Löschung der Inserate und der Registrierungen. (3)
        Die Inserate sind vorerst zeitlich unbeschränkt über die Datenbank
        abrufbar, sofern sie nicht vom Nutzer selbst gelöscht werden.
        Verschiedene Inseratspakete können die Dauer einzelner Anzeigegruppen
        ebenso beschränken. (4) Die Platzierung von Inseraten liegt im Ermessen
        von Zpeeky. Zpeeky ist jederzeit berechtigt, die Verfügbarkeit von
        Inseraten in der Datenbank zu begrenzen sowie Inserate aus der Datenbank
        zu löschen oder die Weiterleitung von Inseraten zu verhindern, wenn
        diese nicht ordnungsgemäß sind, nach Meinung von Zpeeky rechtswidrig
        sind oder durch Dritte beanstandet wurden.
      </Text>
      <Heading mt='4' size='md'>
        § 5 Verantwortlichkeit für Inhalte
      </Heading>
      <Text>
        (1) Der Nutzer ist für die von ihm eingestellten Inhalte selbst
        verantwortlich. Er steht insbesondere dafür ein, dass die Inhalte nicht
        rechtswidrig sind und keine Rechte Dritter (z.B. Urheber- oder
        Markenrechte) verletzen. Zpeeky ist nicht zur Prüfung verpflichtet, ob
        ein eingestellter Inhalt Rechte Dritter beeinträchtigt oder gegen
        gesetzliche Vorschriften verstößt. Der Nutzer ist verpflichtet, die von
        ihm eingestellten Inhalte zu berichtigen bzw. zu löschen, sofern sie
        nicht ordnungsgemäß sind. Änderungen kann der Nutzer über das Menü „Mein
        Konto“ vornehmen. (2) Der Nutzer stellt Zpeeky von allen Ansprüchen
        Dritter einschließlich der anfallenden Rechtsverfolgungskosten frei, die
        dadurch entstehen, dass Inhalte des Nutzers in unveränderter Weise in
        die Datenbank von Zpeeky übernommen werden. (3) Der Nutzer hat dafür
        Sorge zu tragen, dass von ihm übermittelte Dateien keine Viren oder
        vergleichbare schädliche Programme enthalten. Entsprechende Dateien kann
        Zpeeky löschen, ohne dass dem Nutzer hieraus Ansprüche zustehen. Zpeeky
        behält sich Ersatzansprüche wegen virenbedingter Schäden vor.
      </Text>
      <Heading mt='4' size='md'>
        § 6 Nutzungsrechte
      </Heading>
      <Text>
        Durch das Übermitteln von Inseraten räumt der Nutzer Zpeeky und ihren
        verbundenen Unternehmen (Dienstleister und Partner) an den übermittelten
        Inhalten die folgenden nichtausschließlichen, übertragbaren, zeitlich
        und räumlich unbeschränkten Rechte (einschließlich des Rechts zur
        Erteilung von Unterlizenzen) ein: • das Archivierungs- und
        Datenbankrecht, d.h. das Recht, die Inhalte in jeder Form zu archivieren
        und insbesondere auch digitalisiert zu erfassen, in Datenbanken
        einzustellen und auf allen bekannten Speichermedien und auf beliebigen
        Datenträgern zu speichern und mit anderen Werken oder Werkteilen zu
        verbinden; • das Vervielfältigungs- und Verbreitungsrecht, d.h. das
        Recht, die Inhalte beliebig zu speichern, zu vervielfältigen und in
        elektronischen oder anderen Medien (z. B. Zeitungen, Zeitschriften) ganz
        oder teilweise zugänglich zu machen oder zu verbreiten; • das
        Bearbeitungsrecht, d.h. das Recht, die Inhalte beliebig zu bearbeiten,
        insbesondere zu ändern, zu kürzen, zu ergänzen und mit anderen Inhalten
        zu verbinden. Zpeeky ist es insbesondere auch gestattet, die
        vorgenannten Handlungen durch Dritte vornehmen zu lassen.
      </Text>
      <Heading mt='4' size='md'>
        § 7 Rechte an der Datenbank
      </Heading>
      <Text>
        (1) Zpeeky ist Rechtsinhaber an den Inhalten der Website und der
        Datenbank. Sämtliche Urheber-, Marken- und sonstige Schutzrechte an dem
        Datenbankwerk, der Datenbank und den hier eingestellten Inhalten, Daten
        und sonstigen Elementen stehen ausschließlich Zpeeky zu. Etwaige Rechte
        des Nutzers an den von ihm eingestellten Inhalten bleiben hiervon
        unberührt. (2) Der Nutzer hat im Rahmen dieser Nutzungsbedingungen das
        Recht, ausschließlich unter Verwendung der von Zpeeky zur Verfügung
        gestellten Online-Such- & Bearbeitungsmasken einzelne Datensätze auf
        seinem Bildschirm sichtbar zu machen und zur dauerhaften Sichtbarmachung
        einen Ausdruck zu fertigen. Eine automatisierte Abfrage durch Scripte,
        durch Umgehung der Suchmaske durch Suchsoftware oder vergleichbare
        Maßnahmen sind nicht gestattet. (3) Der Nutzer darf die durch Abfrage
        gewonnenen Daten weder vollständig, noch teilweise oder auszugsweise zum
        Aufbau einer eigenen Datenbank in jeglicher Form für jegliche Zwecke
        (z.B. gewerblich, Auskunftserteilung) nutzen. Die Verlinkung,
        Integration oder sonstige Verknüpfung der Datenbank oder einzelner
        Elemente der Datenbank mit anderen Datenbanken oder Meta-Datenbanken ist
        unzulässig.
      </Text>
      <Heading mt='4' size='md'>
        § 8 Kosten
      </Heading>
      <Text>
        Das Einstellen von Inseraten kostet paketabhängig und ist explizit
        ausgewiesen. Eine kostenlose Anmeldung mit eingeschränkter Nutzung der
        Datenbank ist möglich. Die Nutzung bestimmter Inhalte und Funktionen von
        Zpeeky ist kostenpflichtig. Die Inanspruchnahme kostenpflichtiger
        Services ist dem Endnutzer überlassen. Zpeeky behält sich vor die
        Preisgestaltung zu einem beliebigen Zeitpunkt zu ändern. Davon können in
        der Datenbank vorhandene und bereits veröffentlichte Inserate betroffen
        sein. Es gibt eine Übergangszeit von 4 Wochen ab Ankündigung bis zur
        Deaktivierung der bestehenden Inserate, falls vom Nutzer keine
        Umstellung vorgenommen wird.
      </Text>
      <Heading mt='4' size='md'>
        § 9 Gewährleistung
      </Heading>
      <Text>
        (1) Zpeeky übernimmt keine Gewähr für die Richtigkeit und
        Vollständigkeit der von Nutzern gemachten Angaben und abgegebenen
        Erklärungen sowie für die Identität und Integrität der Nutzer. (2) Die
        in die Datenbank eingestellten Inhalte bzw. Inserate sind für Zpeeky
        fremde Inhalte nach § 8 Absatz 1 Telemediengesetz (TMG). Die rechtliche
        Verantwortung für diese Inhalte liegt demgemäß bei derjenigen Person,
        welche die Inhalte in die Datenbank eingestellt hat. (3) Zpeeky
        übernimmt keine Gewährleistung für technische Mängel, insbesondere für
        die ständige und ununterbrochene Verfügbarkeit der Datenbank und ihrer
        Inhalte oder für die vollständige und fehlerfreie Wiedergabe der vom
        Nutzer in die Datenbank eingestellten Inhalte.
      </Text>
      <Heading mt='4' size='md'>
        § 10 Haftung
      </Heading>
      <Text>
        (1) Zpeeky haftet für Datenverluste sowie Kosten nutzloser Dateneingabe
        im Rahmen der vorstehenden Bestimmungen nur in dem Umfang, der sich auch
        dann nicht vermeiden hätte lassen, wenn der Nutzer die bei ihm
        vorhandenen Daten jeweils im jüngsten Bearbeitungsstand in
        maschinenlesbarer Form gesichert hätte. (2) Ein etwaiges Mitverschulden
        des Nutzers ist in jedem Falle zu berücksichtigen. Der Nutzer ist
        insbesondere verpflichtet, die von ihm eingestellten und von Zpeeky
        dargestellten Daten wenigstens durch einmalige Suchabfrage auf deren
        Richtigkeit zu überprüfen. (3) Die vorstehenden Bestimmungen gelten auch
        zugunsten der Mitarbeiter von Zpeeky.
      </Text>
      <Heading mt='4' size='md'>
        § 11 Kündigung
      </Heading>
      <Text>
        (1) Nutzer können die Services von Zpeeky jederzeit mit einer Frist von
        3 Monaten zum Vertragsende kündigen. Die Nutzerdaten bleiben mindestens
        bis zum Vertragsende bestehen. Es erfolgt automatisch ein Wechsel in
        eine kostenlose Mitgliedschaft. Nach Vertragsende kann ein Nutzer seine
        Registrierung jederzeit selbst löschen. Ab diesem Zeitpunkt ist es für
        den Nutzer nicht mehr möglich, neue Inserate einzustellen oder
        bestehende Inserate zu bearbeiten. (2) Zpeeky kann die Services
        jederzeit mit einer Frist von 14 Tagen gegenüber den Nutzern kündigen.
        Das Recht zur Sperrung einer Registrierung oder zur Löschung einzelner
        Inserate bleibt hiervon unberührt.
      </Text>
      <Heading mt='4' size='md'>
        § 12 Schlussbestimmungen
      </Heading>
      <Text>
        (1) Die Rechtsverhältnisse zwischen Zpeeky und dem Nutzer unterliegen
        dem Recht der Bundesrepublik Deutschland unter Ausschluss des
        UN-Kaufrechts. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als
        nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des
        Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat,
        entzogen wird. (2) Gerichtsstand ist Hamburg, soweit der Kunde Kaufmann
        oder eine juristische Person des öffentlichen Rechts oder
        öffentlich-rechtliches Sondervermögen ist. Dasselbe gilt, wenn der Kunde
        keinen allgemeinen Gerichtsstand in Deutschland oder der EU hat oder
        Wohnsitz oder gewöhnlicher Aufenthalt im Zeitpunkt der Klageerhebung
        nicht bekannt sind. Die Befugnis, auch das Gericht an einem anderen
        gesetzlichen Gerichtsstand anzurufen bleibt hiervon unberührt.
      </Text>
      <Text>
        Verbraucherinformation gemäß Verordnung (EU) Nr. 524/2013 Im Rahmen der
        Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten
        steht unter https://ec.europa.eu/consumers/odr/ eine
        Online-Streitbeilegungsplattform der EU-Kommission zur Verfügung. Wir
        nehmen nicht an einem Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teil
      </Text>
    </>
  )
}
