import {
  IconButton,
  IIconButtonProps,
  IIconProps,
  useColorModeValue
} from 'native-base'

export default function TabButton({
  active,
  icon,
  onPress,
  ...props
}: {active: boolean; icon: IIconProps} & Omit<IIconButtonProps, 'icon'>) {
  const bgColor = useColorModeValue('primary.50', 'primary.900')
  return (
    <IconButton
      flex='1'
      maxW='200'
      alignItems='center'
      _icon={icon}
      variant='ghost'
      onPress={onPress}
      backgroundColor={active ? bgColor : 'none'}
      borderRadius='none'
      borderBottomWidth={active ? 4 : 0}
      borderBottomStyle='solid'
      borderBottomColor='primary.500'
      {...props}
    />
  )
}
