import {formatDistance} from 'date-fns'
import {Timestamp} from 'firebase/firestore'

/**
 * This function needs to be refactored and internationalized.
 * @todo Redo this with Expo. See https://docs.expo.dev/versions/latest/sdk/localization/
 * */
export function getRelativeTime(timestamp: number | Timestamp) {
  const _timestamp =
    timestamp instanceof Timestamp ? timestamp.seconds * 1000 : timestamp
  return formatDistance(_timestamp, Date.now(), {addSuffix: true})
}

/**
 * Converts a given duration in minutes to a string representation.
 * E.g. displayDuration(1.25): 1:20
 */
export function displayDuration(duration: number) {
  const minutes = (duration % 60).toFixed(0)
  const seconds = ((duration % 1) * 60).toFixed()
  if (duration > 60) {
    const hours = (duration / 60).toFixed(0)

    return `${hours}:${addLeadingZero(minutes)}:${seconds}`
  } else {
    return `${minutes}:${addLeadingZero(seconds)}`
  }
}

/**
 * Adds a leading zero to numbers smaller 10.
 * @param num number
 * @returns string
 */
function addLeadingZero(num: number | string): string {
  return ('0' + num).slice(-2)
}
