import {useMachine} from '@xstate/react'
import {Column, Heading} from 'native-base'
import React, {useCallback, useState} from 'react'

import AuthStart from '../components/auth/AuthStart'
import SignIn from '../components/auth/SignIn'
import SignUp from '../components/auth/SignUp'
import {AuthContext, initialAuthForm} from '../components/auth/authContext'
import authMachine, {UserAuthState} from '../components/auth/authStateMachine'
import Page from '../components/ui/Page'
import LOCAL from '../misc/localisation'

// TODO: Add loading spinner to buttons?
export default function AuthenticationScreen() {
  const [state, send] = useMachine(authMachine)
  const [isLoading, setLoading] = React.useState(false)
  const [form, setForm] = useState(initialAuthForm)

  const handleChange = useCallback(
    (key: keyof typeof initialAuthForm) => (value: string | boolean) =>
      setForm(form => ({...form, [key]: value})),
    []
  )

  let Body = () => <></>
  switch (state.value) {
    case UserAuthState.Start:
      Body = AuthStart
      break
    case UserAuthState.Registered:
      Body = SignIn
      break
    case UserAuthState.New:
      Body = SignUp
      break
  }

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        setLoading,
        send,
        form,
        setForm,
        handleChange
      }}
    >
      <Page>
        <Column
          w='full'
          h='full'
          space='2'
          justifyContent='center'
          alignSelf='center'
          alignItems='center'
        >
          <Heading>{LOCAL.WELCOME_TO_ZPEEKY}</Heading>
          <Body />
        </Column>
      </Page>
    </AuthContext.Provider>
  )
}
