import {Entypo, FontAwesome5} from '@expo/vector-icons'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {
  Box,
  IImageProps,
  Column,
  Pressable,
  Row,
  StyledProps,
  Text,
  Icon
} from 'native-base'
import React from 'react'

import {StageParamList} from '../../navigation/types'
import {TSpeech} from '../../types/types'
import {displayDuration, getRelativeTime} from '../../utils/timeUtils'
import Thumbnail from '../ui/Thumbnail'

export default function SpeechCard({
  speech,
  w = '300',
  h = '300',
  lineHeight = 'sm',
  resizeMode,
  speakerView,
  ...props
}: {speakerView?: boolean; speech: TSpeech} & StyledProps &
  Pick<IImageProps, 'resizeMode'>) {
  const navigation = useNavigation<StackNavigationProp<StageParamList>>()
  const thumbnail = speech.thumbnails?.find(thumbnail => thumbnail.preview)?.url
  const date = speech.date ? getRelativeTime(speech.date) : ''

  return (
    <Pressable onPress={() => navigation.navigate('Speech', {id: speech.id})}>
      <Column w={w} space='2' justifyContent='space-between' {...props}>
        <Column
          w='full'
          h={h}
          background='black'
          borderRadius='12'
          overflow='hidden'
        >
          <Thumbnail
            uri={thumbnail}
            alt={`${speech.title} (${speech.id})`}
            resizeMode={resizeMode}
          />
          <Box
            bg={{
              linearGradient: {
                colors: ['transparent', 'muted.900']
              }
            }}
            position='absolute'
            top='0'
            left='0'
            right='0'
            bottom='0'
          />
          <Row
            position='absolute'
            right='0'
            bottom='0'
            left='0'
            justifyContent='space-between'
            alignItems='flex-end'
            space='2'
            p='3'
          >
            <Text
              isTruncated
              noOfLines={5}
              flexGrow='1'
              fontSize='lg'
              lineHeight={lineHeight}
              color='lightText'
              bold
            >
              {speech.title}
            </Text>
          </Row>
        </Column>
        <Column px={2}>
          <Row
            space='sm'
            justifyContent='space-between'
            alignItems='center'
            w='full'
            maxW={w}
          >
            <Row flexGrow={1} space={2} alignItems='center'>
              <Icon as={Entypo} size='xs' name='eye' />
              <Text>{speech.metrics?.views ?? 0}</Text>
            </Row>
            <Row
              flexGrow={1}
              space={2}
              alignItems='center'
              justifyContent='center'
            >
              <Icon as={FontAwesome5} size='xs' name='star-half-alt' />
              <Text>{speech.metrics?.evaluations ?? 0}</Text>
            </Row>
            <Text flexGrow={1} textAlign='right'>
              {speech.duration ? displayDuration(speech.duration) : ''}
            </Text>
          </Row>
          <Text isTruncated noOfLines={1} fontSize='md'>
            {speakerView ? date : speech.speaker}
          </Text>
        </Column>
      </Column>
    </Pressable>
  )
}
