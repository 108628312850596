import {DocumentResult} from 'expo-document-picker'
import {
  Box,
  Button,
  Column,
  FlatList,
  FormControl,
  Image,
  Input,
  Pressable,
  Row,
  StyledProps
} from 'native-base'
import React, {memo, useCallback, useEffect, useState} from 'react'
import uuid from 'react-native-uuid'

import {
  MAX_VIDEO_DURATION_IN_MIN,
  MAX_VIDEO_FILE_SIZE_IN_MB,
  MIN_VIDEO_DURATION_IN_MIN
} from '../../constants/general'
import useToast from '../../hooks/useToast'
import LOCAL from '../../misc/localisation'
import {TSpeechThumbnails} from '../../types/types'
import FilePicker from '../../utils/FilePicker'
import Logger from '../../utils/Logger'
import VideoProcessor from '../../utils/VideoProcessor'
import {bToMb} from '../../utils/fileUtils'
import VideoPlayer from './VideoPlayer'

export type TFile =
  | {type: 'cancel' | 'processing' | 'error'}
  | (DocumentResult & {duration?: number})

// TODO: The APIs of FilePicker and VideoProcessor need an rework.
// Plus, we need to clean up the types used from file picking to processing to upload to have clear consistent types.
export const VideoFileInput = memo(
  ({
    file = {type: 'cancel'},
    setFile,
    setThumbnails: updateThumbnails,
    duration = {min: MIN_VIDEO_DURATION_IN_MIN, max: MAX_VIDEO_DURATION_IN_MIN},
    maxSizeMb = MAX_VIDEO_FILE_SIZE_IN_MB,
    ...props
  }: {
    file: TFile
    setFile: (result: TFile) => void
    setThumbnails: (thumbnails: TSpeechThumbnails) => void
    duration?: {min: number; max: number}
    maxSizeMb?: number
  } & StyledProps) => {
    const displayValue = getDisplayValue(file)
    const [thumbnails, setThumbnails] = useState<TSpeechThumbnails>([])
    const setThumbnailPreview = useCallback((index: number) => {
      setThumbnails(thumbnails =>
        thumbnails.map((thumbnail, i) => ({
          ...thumbnail,
          preview: index === i
        }))
      )
    }, [])

    useEffect(() => {
      updateThumbnails(thumbnails)
    }, [thumbnails, updateThumbnails])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const renderThumbnail = useCallback(
      prepareRenderThumbnail(setThumbnailPreview),
      [setThumbnailPreview]
    )

    const showToast = useToast()

    const isProcessing = file.type === 'processing'

    const pickDocument = useCallback(async () => {
      setFile({type: 'processing'})

      const result = await FilePicker.pickVideo()

      Logger.debug({result})

      if (result) {
        try {
          const video = new VideoProcessor(result)
          Logger.debug({video})

          const fileSize = await video.getFileSize()
          if (fileSize > maxSizeMb) {
            const message = `File is too big: ${bToMb(fileSize).toFixed(2)}mb`
            showToast(message, 'error')
            throw new Error(message)
          }
          Logger.debug({fileSize})

          const duration = await video.getVideoDuration(result.uri)
          Logger.debug({duration})

          Logger.debug(result)

          if (duration > MAX_VIDEO_DURATION_IN_MIN) {
            const message = `Video is too long: ${duration.toFixed(
              2
            )} minutes. Maximum duration is ${MAX_VIDEO_DURATION_IN_MIN} minutes.`
            showToast(message, 'error')
            throw new Error(message)
          }
          Logger.debug('about to generate thumbnails')
          const images = await video.generateThumbnails()
          Logger.debug(images)
          const _thumbnails = images.map((image, index) => ({
            url: image,
            preview: index === 0
          }))
          setThumbnails(_thumbnails)
          setFile({
            ...video,
            duration,
            type: 'success',
            uri: result.uri,
            name: video.getName() ?? `${uuid.v4()}.${video.getFileExtension()}`,
            size: fileSize
          })
        } catch (error) {
          Logger.error(error)
          setFile({type: 'error'})
        }
      } else {
        setFile({type: 'error'})
      }
    }, [maxSizeMb, setFile, showToast])

    return (
      <Column space='4'>
        <Column>
          <FormControl {...props}>
            <FormControl.Label>{LOCAL.SPEECH.VIDEO} *</FormControl.Label>
            <Row space='4'>
              <Input
                isDisabled
                value={displayValue}
                onChangeText={nop}
                flex='1'
              />
              <Button
                onPress={pickDocument}
                isLoading={isProcessing}
                isDisabled={isProcessing}
              >
                {isProcessing ? LOCAL.SPEECH.PROCESSING : LOCAL.SPEECH.FILE}
              </Button>
            </Row>
          </FormControl>
        </Column>
        {file.type === 'success' && <VideoPlayer uri={file.uri} />}
        {Boolean(thumbnails?.length) && (
          <FlatList
            horizontal
            ItemSeparatorComponent={ThumbnailsSeperator}
            data={thumbnails}
            renderItem={renderThumbnail}
          />
        )}
      </Column>
    )
  },
  (prev, next) => prev.file === next.file
)

const prepareRenderThumbnail =
  (setThumbnailPreview: (index: number) => void) =>
  ({item, index}: {item: TSpeechThumbnails[0]; index: number}) => {
    const {preview, url} = item
    return (
      <Pressable key={index} onPress={() => setThumbnailPreview(index)}>
        <Box
          borderColor={preview ? 'primary.500' : 'primary.500:alpha.5'}
          borderStyle='solid'
          borderWidth='4'
          borderRadius='lg'
          padding='1'
        >
          <Image
            resizeMode='contain'
            size='lg'
            alt={`Video thumbnail ${index}`}
            source={{uri: url}}
          />
        </Box>
      </Pressable>
    )
  }

const ThumbnailsSeperator = () => <Box w='3' />

function getDisplayValue(file: TFile) {
  if (file.type === 'error') return LOCAL.SOMETHING_WENT_WRONG
  if (file.type === 'processing') return ''
  if (file.type === 'success') {
    const fileSize = file.size ? ' (' + file.size + 'mb)' : ''
    return `${file.name} ${fileSize}`
  }
}

const nop = () => {}
