import {getInfoAsync} from 'expo-file-system'
import {Platform} from 'expo-modules-core'
import {getThumbnailAsync} from 'expo-video-thumbnails'

import {TVideoFile} from './FilePicker'
import MimeTypes from './MimeTypes'

/**
 * Processes a video file given as URI string.
 * Returns duration and generates thumbnails.
 */
export default class VideoProcessor {
  private WaitForThumbnailInMS: number = 250
  private uri: string
  private file?: File
  private data: {
    name?: string
    mimeType: string
    /** Duration in minutes. */
    duration?: number
    /** File size in mb. */
    fileSize?: number
  } = {
    mimeType: '',
    duration: undefined,
    fileSize: undefined
  }

  constructor(data: TVideoFile) {
    this.uri = data.uri
    this.file = data.file
    this.data.duration = data.duration ? data.duration / 60 : undefined
    this.data.name = data.name

    if (data.size) {
      this.data.fileSize = Number(this.bToMb(data.size).toFixed(2))
    }

    if (data.mimeType) {
      this.data.mimeType = data.mimeType
    } else {
      const mimeType = /:(.*?);/.exec(data.uri)
      if (mimeType?.[1]) this.data.mimeType = mimeType[1]
    }
  }

  /**
   * Generates a given number of thumbnails from the video.
   * @param num Optional number of thumbnails.
   * @returns Array containing image URIs.
   */
  public generateThumbnails(num: number = 3): Promise<string[]> {
    return this._generateThumbnails(num)
  }

  /** Returns the file size in MB. */
  public async getFileSize(): Promise<number> {
    return this.data.fileSize ?? this.calculateFileSize()
  }

  /** Returns the mime type. */
  public getMimeType(): string {
    return this.data.mimeType
  }

  /** Returns the name. */
  public getName(): string | undefined {
    return this.data.name
  }

  // TODO: Filename and extension should be provided from the picker. Maybe choose better methods / libraries from Expo or other.
  /** Returns the file extension. */
  public getFileExtension(): string {
    return String(MimeTypes.getFileExtension(this.data.mimeType))
  }

  /** Returns the video duration in minutes. */
  public async getVideoDuration(uri: string): Promise<number> {
    return new Promise(resolve => {
      if (Platform.OS === 'web') {
        const video = document.createElement('video')
        video.preload = 'metadata'
        video.onloadedmetadata = () => {
          const duration: number = Number((video.duration / 60).toFixed(2))
          resolve(duration)
        }
        video.src = uri
      } else {
        return resolve(this.data.duration!)
      }
    })
  }

  private async calculateFileSize(): Promise<number> {
    let size = 0
    if (Platform.OS !== 'web') {
      size = (await getInfoAsync(this.uri)).size ?? 0
    }

    this.data.fileSize = Number(this.bToMb(size).toFixed(2))
    return this.data.fileSize
  }

  private bToMb(size: number): number {
    return size / (1024 * 1024)
  }

  // private milisecToMin(ms: number): number {
  //   return ms / (1000 * 60)
  // }

  private _generateThumbnailWeb(
    playbackPositionInSec: number
  ): Promise<string> {
    return new Promise(async resolve => {
      const canvas = document.createElement('canvas')
      const video = document.createElement('video')

      video.autoplay = true
      video.muted = true
      video.src = this.uri

      video.currentTime = playbackPositionInSec
      video.pause()

      video.onloadeddata = async ev => {
        await wait(this.WaitForThumbnailInMS)

        const ctx = canvas.getContext('2d')
        if (!(ctx instanceof CanvasRenderingContext2D))
          throw new Error('Canvas context is not defined.')

        canvas.width = video.videoWidth
        canvas.height = video.videoHeight

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
        resolve(canvas.toDataURL('image/png'))
      }
    })
  }

  private _generateThumbnails(num: number): Promise<string[]> {
    return new Promise(async resolve => {
      const imageUris: string[] = []
      const durationInSec =
        (this.data.duration ?? (await this.getVideoDuration(this.uri))) * 60
      const timeStepInSec = durationInSec / 3

      if (Platform.OS === 'web') {
        for (let index = 0; index < num; index++) {
          const playbackPositionInSec = index * timeStepInSec
          const imageUri = await this._generateThumbnailWeb(
            playbackPositionInSec
          )
          imageUris.push(imageUri)
        }
        return resolve(imageUris)
      } else {
        const timeStepInMS = timeStepInSec * 1000
        for (let index = 0; index < num; index++) {
          const {uri} = await getThumbnailAsync(this.uri, {
            time: index * timeStepInMS
          })
          imageUris.push(uri)
        }
        return resolve(imageUris)
      }
    })
  }
}

function wait(milliseconds: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
