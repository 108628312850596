const words = {
  WELCOME_TO_ZPEEKY: 'Welcome to Zpeeky',
  ADMIN: 'Admin',
  FEED: 'Feed',
  STAGE: 'Stage',
  DISCOVER: 'Discover',
  COACH: 'Coach',
  PROFILE: 'Profile',
  SAVE: 'Save',
  SUBMIT: 'Submit',
  CANCEL: 'Cancel',
  PROCESSING: 'Processing ...',
  LOADING: 'loading ...',
  EDITED: 'Edited: ',
  SOMETHING_WENT_WRONG:
    'Sorry, but something went wrong ...\nPlease, try again later.',
  AUTH: {
    EMAIL: 'Email',
    EMAIL_IN_USE: `Sorry, but a user with this email is already signed up.\n
    If this is your address, try to sign in with your password instead.`,
    EMAIL_INVALID: 'Email is invalid.',
    EMAIL_MISSING: 'Please, type in a valid email.',
    GET_STARTED: 'Get started',
    READ_AND_AGREE: 'I have read and agree to the ',
    PASSWORD: 'Password',
    PASSWORD_CHECK_MAIL:
      'Please, check your mail inbox to reset your password.',
    PASSWORD_CONFIRM: 'Confirm Password',
    PASSWORD_FORGOTTEN_Q: 'Forgot your password?',
    PASSWORD_TOO_SHORT: 'Your password should at least contain 6 digits.',
    PASSWORD_WRONG: 'Wrong Password. Please, try again.',
    RESET_PASSWORD: 'Reset password',
    SIGN_IN: 'Sign in',
    SIGN_OUT: 'Sign out',
    SIGN_UP: 'Sign up',
    SIGNED_IN_SUCCESSFULLY: 'Successfully signed in.',
    TERMS_OF_USE: 'Terms of Use',
    PRIVACY_POLICY: 'Privacy Policy',
    TOO_MANY_REQUESTS:
      'Access to this account has been temporarily disabled due to many failed login attempts. Please, try again later.',
    USER_NOT_FOUND: 'No account with this user exists.'
  },
  SPEECH: {
    CATEGORY: 'Category',
    COUNTRY: 'Country',
    DESCRIPTION: 'Description',
    EVALUATE: 'evaluate',
    EVALUATIONS: 'evaluations',
    FILE: 'File',
    GENRE: 'Genre',
    PROCESSING: 'Processing ...',
    SPEECH_OF_THE_WEEK: 'Speech of the week',
    TITLE: 'Title',
    UPLOAD_ANOTHER_SPEECH: 'Upload another speech',
    UPLOAD_SPEECH: 'Upload your speech',
    UPLOADED_SPEECH: 'Uploaded speech ...',
    UPLOADING_SPEECH: 'Uploading speech ...',
    UPLOADING_SPEECH_ERROR: 'There was an error uploading your speech.',
    LANGUAGE: 'Language',
    VIDEO: 'Video',
    VIEWS: 'views',
    VIEW_SPEECH: 'View your speech',
    NO_VIDEO_UPLOADED: 'No video has been uploaded for this speech.'
  },
  USER: {
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    SHOW_PROFILE_OF: (name: string) => `Show ${name}'s profile`
  },
  SOCIAL: {
    SHARE: 'share'
  },
  EVALUATION: {
    EVALUATION_FROM_ME: 'Evaluation from me',
    PRAISE: 'What I liked',
    SUGGESTIONS: (firstname: string) => `What ${firstname} can improve`,
    EVALUATE_SPEACH_FROM: (firstname: string, title: string) =>
      `Evaluate ${firstname}'s speech \n"${title}"`,
    EVALUE_DIMENSIONS_FROM: (firstname: string) =>
      `How did ${firstname} perform regarding these aspects.`,
    DIMENSIONS: 'Speech Dimensions',
    COMPREHENSIBILITY: 'Comprehensibility and Structure',
    CREDIBILITY: 'Credible and Convincing',
    BODY_LANGUAGE: 'Gestures And Facial Expressions',
    VOCAL_QUALITY: 'Pronounciation and Vocal Variety',
    STYLISTIC_DEVICES: 'Stylistic Devices',
    TITLE: 'Title'
  },
  TIME: {
    JUST_NOW: 'Just now',
    YESTERDAY: 'Yesterday',
    LAST_WEEK: 'Last week',
    A_MONTH_AGO: 'A month ago',
    A_YEAR_AGO: 'A year ago'
  },
  SETTINGS: {
    SETTINGS: 'Settings',
    SET_LIGHT_MODE: 'Activate light mode',
    SET_DARK_MODE: 'Activate dark mode',
    NOTIFICATIONS: {
      NOTIFICATIONS: 'Notifications',
      REASONS: {
        'new-evaluation': 'Received new evaluation',
        'updated-evaluation': 'Received updated evaluation',
        'new-speech-by-followee': 'New speech by user I follow',
        'new-follower': 'New follower',
        comment: 'Comments on my activities',
        like: 'Likes on my activities'
      }
    }
  },
  NAVIGATION: {
    BACK: 'back'
  }
}

const LOCAL = () => words

export default LOCAL()
