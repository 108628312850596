import {Button, Center, Column, Heading, Text} from 'native-base'
import React, {useState} from 'react'

import Page from '../components/ui/Page'
import {countEvaluations} from '../services/evaluation'
import useQueryUser from '../services/useQueryUser'
import Logger from '../utils/Logger'

export default function ProfileScreen() {
  const {data: user, isLoading} = useQueryUser()
  const isAdmin = user?.role === 'admin'
  const [countEvaluationsLoading, setCountEvaluationsLoading] = useState(false)

  return (
    <Page>
      <Center h='full' p='4'>
        {isLoading && <Text>Loading ...</Text>}
        {isAdmin ? (
          <Column space='md'>
            <Heading>Admin Dashboard</Heading>
            <Column justifyItems='start'>
              <Button
                isDisabled={countEvaluationsLoading}
                isLoading={countEvaluationsLoading}
                onPress={() => {
                  setCountEvaluationsLoading(true)
                  countEvaluations()
                    .then(res =>
                      Logger.debug('countEvaluations Response:', res)
                    )
                    .catch(err => Logger.error('countEvaluations Error:', err))
                    .finally(() => setCountEvaluationsLoading(false))
                }}
              >
                Count Evaluations
              </Button>
            </Column>
          </Column>
        ) : (
          <Text>Access not allowed.</Text>
        )}
      </Center>
    </Page>
  )
}
