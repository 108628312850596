import {StatusBar} from 'expo-status-bar'
import {useColorModeValue} from 'native-base'

import theme from '../../theme/theme'

export default function _StatusBar() {
  const backgroundColor = useColorModeValue(
    theme.colors.light[50],
    theme.colors.light[900]
  )
  const color = useColorModeValue('dark', 'light')

  return <StatusBar style={color} backgroundColor={backgroundColor} />
}
