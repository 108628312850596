import {createMachine} from 'xstate'

export enum UploadState {
  Editing = 'Editing',
  Uploading = 'Uploading',
  Error = 'Error',
  Uploaded = 'Uploaded'
}

export enum UploadStateEvents {
  SUBMIT = 'SUBMIT',
  UPLOADED = 'UPLOADED',
  ERROR = 'ERROR',
  RESET = 'RESET'
}

const uploadMachine = createMachine({
  id: 'upload',
  initial: UploadState.Editing,
  states: {
    [UploadState.Editing]: {
      on: {
        [UploadStateEvents.SUBMIT]: UploadState.Uploading,
        [UploadStateEvents.ERROR]: UploadState.Error
      }
    },
    [UploadState.Uploading]: {
      on: {
        [UploadStateEvents.RESET]: UploadState.Editing,
        [UploadStateEvents.ERROR]: UploadState.Error,
        [UploadStateEvents.UPLOADED]: UploadState.Uploaded
      }
    },
    [UploadState.Uploaded]: {
      on: {
        [UploadStateEvents.RESET]: UploadState.Editing
      }
    },
    [UploadState.Error]: {
      on: {
        [UploadStateEvents.RESET]: UploadState.Editing
      }
    }
  }
})

export default uploadMachine
