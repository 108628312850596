export enum QueryKeys {
  Speech = 'Speech',
  FocusSpeech = 'FocusSpeech',
  PrimeSpeech = 'PrimeSpeech',
  Speeches = 'Speeches',
  SpeechesByUser = 'SpeechesByUser',
  SpeechesByEvaluator = 'SpeechesByEvaluator',
  Evaluation = 'Evaluation',
  Evaluations = 'Evaluations',
  User = 'User',
  ContentSettings = 'ContentSettings'
}
