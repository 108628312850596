import {FontAwesome5} from '@expo/vector-icons'
import {IconProps} from '@expo/vector-icons/build/createIconSet'
import {Column, Icon, Pressable, Text} from 'native-base'
import React, {ReactNode} from 'react'

export default function LabeledIcon({
  size,
  name,
  label,
  onPress
}: {label: string} & IconProps<any>) {
  const WithPressable = ({children}: {children: ReactNode}) =>
    onPress ? (
      <Pressable onPress={onPress}>{children}</Pressable>
    ) : (
      <>{children}</>
    )

  return (
    <WithPressable>
      <Column py='2' justifyContent='flex-end' alignItems='center'>
        <Icon as={FontAwesome5} size={size} name={name} />
        <Text>{label}</Text>
      </Column>
    </WithPressable>
  )
}
