import {Button} from 'native-base'
import React, {useCallback, useState} from 'react'

import useToast from '../../hooks/useToast'
import LOCAL from '../../misc/localisation'
import {resetPassword} from '../../services/user'
import Logger from '../../utils/Logger'

export default function ResetPassword({email}: {email: string}) {
  const showToast = useToast()
  const [isLoading, setLoading] = useState(false)

  const handlePasswordReset = useCallback(() => {
    setLoading(true)
    resetPassword(email)
      .then(() => {
        showToast(LOCAL.AUTH.PASSWORD_CHECK_MAIL)
      })
      .catch(e => {
        Logger.debug(e)
        showToast(LOCAL.SOMETHING_WENT_WRONG)
      })
      .finally(() => setLoading(false))
  }, [email, showToast])

  return (
    <Button
      colorScheme='secondary'
      variant='outline'
      isDisabled={isLoading}
      onPress={handlePasswordReset}
    >
      {LOCAL.AUTH.RESET_PASSWORD}
    </Button>
  )
}
