import React from 'react'

type TStateMachineContext = {
  upload: {
    send: Function
    state: {value: any; context: any}
  }
}

const initialStateMachineContext = {
  upload: {state: {value: {}, context: {}}, send: () => {}}
} as TStateMachineContext

export const StateMachineContext = React.createContext(
  initialStateMachineContext
)
