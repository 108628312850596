import React, {useCallback, useState} from 'react'
import {useQuery} from 'react-query'

import {QueryKeys} from '../../services/QueryKeys'
import {
  getEvaluations,
  postEvaluation,
  TEvaluationDraft
} from '../../services/evaluation'
import useQueryUser from '../../services/useQueryUser'
import {TSpeech} from '../../types/types'
import EditEvaluation from './EditEvaluation'

const defaultValue = 3
export const initialEvaluation: TEvaluationDraft = {
  title: '',
  praise: '',
  suggestions: '',
  dimensions: {
    comprehensibilty: defaultValue,
    credibility: defaultValue,
    bodyLanguage: defaultValue,
    vocalQuality: defaultValue,
    stylisticDevices: defaultValue
  }
}

export default function SubmitEvaluation({
  speech: {id, speaker, title},
  closeModal
}: {
  speech: TSpeech
  closeModal: () => void
}) {
  const {data: user} = useQueryUser()

  const [evaluation, setEvaluation] = useState(initialEvaluation)

  const {refetch} = useQuery([QueryKeys.Evaluations, id], () =>
    getEvaluations(id)
  )

  const handleSubmit = useCallback(() => {
    if (!user) {
      return
    }

    postEvaluation({
      ...evaluation,
      speechId: id,
      evaluator: {
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.id
      }
    }).then(() => {
      refetch?.()
    })
    closeModal()
  }, [closeModal, evaluation, id, refetch, user])

  const firstName = speaker.split(' ')[0]

  return (
    <EditEvaluation
      speaker={firstName}
      title={title}
      evaluation={evaluation}
      setEvaluation={setEvaluation}
      handleSubmit={handleSubmit}
    />
  )
}
