import {Row} from 'native-base'
import React, {useMemo} from 'react'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'

import {QueryKeys} from '../../services/QueryKeys'
import {getEvaluations} from '../../services/evaluation'
import {selectUserId} from '../../store/reducer/userSlice'
import {TSpeech, TFlag, TEvaluation} from '../../types/types'
import Evaluation from './Evaluation'

export default function Evaluations({
  id,
  speech
}: {
  id: string
  speech: TSpeech
}) {
  const userId = useSelector(selectUserId)

  const {
    isLoading,
    error,
    data: evaluations
  } = useQuery([QueryKeys.Evaluations, id], () => getEvaluations(id))

  const [userEvaluation, otherEvaluations] = useMemo(() => {
    let userEvaluation: TEvaluation | undefined = undefined
    const otherEvaluations: TEvaluation[] = []
    evaluations?.forEach(evaluation => {
      if (evaluation.evaluator.id === userId) {
        userEvaluation = evaluation
      } else {
        otherEvaluations.push(evaluation)
      }
    })

    return [userEvaluation as TEvaluation | undefined, otherEvaluations]
  }, [evaluations, userId])

  const hasNoEvaluations = isLoading || error || evaluations?.length === 0

  return hasNoEvaluations ? (
    <></>
  ) : (
    <Row flexWrap='wrap' space='2'>
      {userEvaluation && !isBanned(userEvaluation.flags) && (
        <Evaluation
          key={userId}
          userEvaluation
          evaluation={userEvaluation}
          speech={speech}
        />
      )}
      {otherEvaluations?.map(evaluation =>
        isBanned(evaluation.flags) ? (
          <></>
        ) : (
          <Evaluation
            key={evaluation.evaluator.id}
            evaluation={evaluation}
            speech={speech}
          />
        )
      )}
    </Row>
  )
}

function isBanned(flags: TFlag[] | undefined): boolean {
  return Number(flags?.length) >= 3
}
