import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {TFile} from '../../components/ui/VideoFileInput'
import {CATEGORY} from '../../constants/Categories'
import languages from '../../misc/languages'
import {TSpeechThumbnails} from '../../types/types'
import {RootState} from '../store'

export type TUpload = {progress: number}

// TODO: Fix types
export type TUploadSpeechForm = {
  id?: string
  title: string
  description: string
  category: string
  language: string
  country: string
  file: TFile
  thumbnails: TSpeechThumbnails
  upload: TUpload
}

export enum FormStates {
  Editing = 'Editing',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Error = 'Error'
}

export const initialForm: TUploadSpeechForm = {
  title: '',
  description: '',
  category: CATEGORY.BIOGRAPHY.toLowerCase(),
  language: languages.de.name,
  country: 'Germany',
  file: {type: 'cancel'},
  thumbnails: [],
  upload: {progress: 0}
}

const initialState = {
  state: FormStates.Editing,
  ...initialForm
}

export type TUploadSpeech = typeof initialState

export const uploadSpeechSlice = createSlice({
  name: 'uploadSpeechSlice',
  initialState,
  reducers: {
    // TODO: Maybe there is a way to merge Redux and XState?
    // TODO: Is it necessary to define state Events instead of target States as payload.
    changeState: (state, {payload}: PayloadAction<FormStates>) => {
      // TODO: Research how this can be refactored.
      switch (state.state) {
        case FormStates.Editing:
          if (payload === FormStates.Uploading) {
            return {...state, state: payload}
          }

          break
        case FormStates.Uploading:
          if (payload === FormStates.Editing) {
            return {...state, state: payload}
          }
          if (payload === FormStates.Uploaded) {
            return {...state, state: payload}
          }
          if (payload === FormStates.Error) {
            return {...state, state: payload}
          }

          break

        case FormStates.Uploaded:
          if (payload === FormStates.Editing) {
            return {...state, state: payload}
          }
          if (payload === FormStates.Error) {
            return {...state, state: payload}
          }

          break

        case FormStates.Error:
          if (payload === FormStates.Editing) {
            return {...state, state: payload}
          }

          break

        default:
          break
      }
    },
    updateForm: (
      state,
      {payload}: PayloadAction<Partial<TUploadSpeechForm>>
    ) => {
      return {...state, ...payload}
    },
    resetForm: () => initialState,
    updateUploadProgress: (state, {payload}: PayloadAction<number>) => {
      return {...state, upload: {progress: payload}}
    }
  }
})

// Action creators are generated for each case reducer function
export const {updateForm, resetForm, changeState, updateUploadProgress} =
  uploadSpeechSlice.actions

export default uploadSpeechSlice.reducer

export const selectUploadSpeechForm = (state: RootState) => state.uploadSpeech

export const selectIsSpeechComplete = (state: RootState) =>
  state.uploadSpeech.title.length > 0 &&
  state.uploadSpeech.description.length > 0 &&
  state.uploadSpeech.file.type === 'success'

export const selectIsUploadInProgress = (state: RootState) =>
  state.uploadSpeech.state === FormStates.Uploading ||
  state.uploadSpeech.state === FormStates.Uploaded
