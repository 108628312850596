export enum ErrorCodes {
  AuthInvalidMail = 'auth/invalid-email',
  AuthWrongPassword = 'auth/wrong-password',
  AuthTooManyRequests = 'auth/too-many-requests',
  AuthUserNotFound = 'auth/user-not-found',
  UploadCancelled = 'storage/canceled',
  StorageUnauthorized = 'storage/unauthorized',
  StorageUnknown = 'storage/unknown',
  FilePickCancelled = 'ui/file-pick-cancelled'
}
