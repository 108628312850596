import {
  collection,
  FirestoreError,
  getDocs,
  query,
  Timestamp,
  where,
  doc,
  updateDoc,
  getDoc,
  serverTimestamp,
  setDoc
} from 'firebase/firestore'
import {httpsCallable, HttpsCallableResult} from 'firebase/functions'

import {TEvaluation} from '../types/types'
import Logger from '../utils/Logger'
import {db, functions} from './firebase'

export type TVote = {
  date: number
  id: string
}

export type TFlag = TVote & {
  reason: string
}

export type VoteType = 'up' | 'down' | 'flag'

export type TVoteEvaluation = {
  id: string
  interaction: VoteType
}

export type TEvaluationDimensions = {
  comprehensibilty: number
  credibility: number
  bodyLanguage: number
  vocalQuality: number
  stylisticDevices: number
}

export type TEvaluationDraft = Required<
  Pick<TEvaluation, 'praise' | 'suggestions' | 'dimensions' | 'title'>
>

export async function getEvaluation(id: string): Promise<TEvaluation> {
  const ref = doc(db, 'evaluations', id)
  return getDoc(ref).then(doc => doc.data() as TEvaluation)
}

export async function getEvaluations(speechId: string): Promise<TEvaluation[]> {
  const q = query(
    collection(db, 'evaluations'),
    where('speechId', '==', speechId)
  )

  return getDocs(q).then(snapshot =>
    snapshot.docs.map(doc => doc.data() as TEvaluation)
  )
}

export type TPostEvaluation = TEvaluationDraft &
  Pick<TEvaluation, 'evaluator'> & {speechId: string}

export async function postEvaluation(evaluation: TPostEvaluation) {
  const ref = doc(collection(db, 'evaluations'))
  return setDoc(ref, {
    ...evaluation,
    date: serverTimestamp(),
    id: ref.id
  })
    .then(() => {
      Logger.debug('Evaluation written with id:', ref.id)
      return ref.id
    })
    .catch((error: FirestoreError) => {
      Logger.error('Error writing document: ', error)
    })
}

export type TPatchEvaluation = TEvaluationDraft & {id: string}

export async function patchEvaluation({id, ...evaluation}: TPatchEvaluation) {
  const ref = doc(db, 'evaluations', id)
  return updateDoc(ref, {
    ...evaluation,
    edited: Timestamp.fromDate(new Date())
  })
    .then(() => Logger.debug('Updated evaluation with id: ', id))
    .catch(error =>
      Logger.error('Error patching evaluation with id: ' + id, error)
    )
}

const _voteEvaluation = httpsCallable(functions, 'voteEvaluation')

export async function voteEvaluation(
  id: string,
  voteType: VoteType
): Promise<HttpsCallableResult<unknown>> {
  return _voteEvaluation({
    id,
    interaction: voteType
  } as TVoteEvaluation)
}

const _countEvaluations = httpsCallable(functions, 'countEvaluations')

export async function countEvaluations(): Promise<
  HttpsCallableResult<unknown>
> {
  return _countEvaluations()
}
