/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

import {Ionicons} from '@expo/vector-icons'
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs'
import {createStackNavigator} from '@react-navigation/stack'
import {Icon} from 'native-base'
import React from 'react'

import Settings from '../components/profile/Settings'
import LOCAL from '../misc/localisation'
import AdminScreen from '../screens/AdminScreen'
import ProfileScreen from '../screens/ProfileScreen'
import SpeechScreen from '../screens/SpeechScreen'
import StageScreen from '../screens/StageScreen'
import useQueryUser from '../services/useQueryUser'
import {BottomTabParamList, StageParamList} from './types'

const BottomTab = createBottomTabNavigator<BottomTabParamList>()

export default function BottomTabNavigator() {
  const {data: user} = useQueryUser()
  const isAdmin = user?.role === 'admin'
  return (
    <BottomTab.Navigator>
      <BottomTab.Screen
        name='Stage'
        component={StageNavigator}
        options={{
          headerShown: false,
          title: LOCAL.STAGE,
          // TODO: Focused
          tabBarIcon: () => <Icon as={Ionicons} name='mic-outline' size={8} />
        }}
      />
      <BottomTab.Screen
        name='Profile'
        component={ProfileScreen}
        options={{
          headerRight: () => <Settings />,
          title: LOCAL.PROFILE,
          tabBarIcon: () => (
            <Icon as={Ionicons} name='person-circle-outline' size={8} />
          )
        }}
      />
      {isAdmin && (
        <BottomTab.Screen
          name='Admin'
          component={AdminScreen}
          options={{
            title: LOCAL.ADMIN,
            tabBarIcon: () => (
              <Icon as={Ionicons} name='settings-outline' size={8} />
            )
          }}
        />
      )}
      {/* <BottomTab.Screen
        name="FeedTab"
        component={FeedNavigator}
        options={{
          headerShown: false,
          title: __.FEED,
          tabBarIcon: ({color}) => <TabBarIcon name='ios-code' color={color} />,
        }}
      /> */}
      {/* <BottomTab.Screen
        name="DiscoverTab"
        component={DiscoverNavigator}
        options={{
          headerShown: false,
          title: __.DISCOVER,
          tabBarIcon: ({color}) => <TabBarIcon name='ios-code' color={color} />,
        }}
      />
      <BottomTab.Screen
        name="CoachTab"
        component={CoachNavigator}
        options={{
          headerShown: false,
          title: __.COACH,
          tabBarIcon: ({color}) => <TabBarIcon name='ios-code' color={color} />,
        }}
      /> */}
    </BottomTab.Navigator>
  )
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
// function TabBarIcon(props: {
//   name: React.ComponentProps<typeof Ionicons>['name']
//   color: string
// }) {
//   return <Ionicons size={30} {...props} />
// }

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
// const FeedStack = createStackNavigator<FeedParamList>()

// function FeedNavigator() {
//   return (
//     <FeedStack.Navigator>
//       <FeedStack.Screen
//         name="Feed"
//         component={FeedScreen}
//         options={{headerShown: false}}
//       />
//     </FeedStack.Navigator>
//   )
// }

const StageStack = createStackNavigator<StageParamList>()

function StageNavigator() {
  return (
    <StageStack.Navigator>
      <StageStack.Screen
        name='Overview'
        component={StageScreen}
        options={{headerShown: false}}
      />
      <StageStack.Screen
        name='Speech'
        component={SpeechScreen}
        options={{headerShown: false}}
      />
    </StageStack.Navigator>
  )
}

// const DiscoverStack = createStackNavigator<DiscoverParamList>()

// function DiscoverNavigator() {
//   return (
//     <DiscoverStack.Navigator>
//       <DiscoverStack.Screen
//         name="Discover"
//         component={DiscoverScreen}
//         options={{headerShown: false}}
//       />
//     </DiscoverStack.Navigator>
//   )
// }

// const CoachStack = createStackNavigator<CoachParamList>()

// function CoachNavigator() {
//   return (
//     <CoachStack.Navigator>
//       <CoachStack.Screen
//         name="Coach"
//         component={CoachScreen}
//         options={{headerShown: false}}
//       />
//     </CoachStack.Navigator>
//   )
// }

// const ProfileStack = createStackNavigator<ProfileParamList>()

// function ProfileNavigator() {
//   return (
//     <ProfileStack.Navigator>
//       <ProfileStack.Screen
//         name="Profile"
//         component={ProfileScreen}
//         options={{headerShown: false}}
//       />
//     </ProfileStack.Navigator>
//   )
// }
