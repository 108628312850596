import {FontAwesome5, Ionicons} from '@expo/vector-icons'
import {
  Box,
  Button,
  Center,
  Column,
  Container,
  FlatList,
  Heading,
  MoonIcon,
  Pressable,
  Row,
  Spinner,
  SunIcon,
  Text,
  useColorMode,
  useColorModeValue
} from 'native-base'
import React, {useCallback, useMemo, useState} from 'react'
import {useWindowDimensions} from 'react-native'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'

import ResetPassword from '../components/auth/ResetPassword'
import Portrait from '../components/profile/Portrait'
import SpeechCard from '../components/stage/SpeechCard'
import UploadSpeechModal from '../components/stage/UploadSpeechModal'
import Page from '../components/ui/Page'
import TabButton from '../components/ui/TabButton'
import LOCAL from '../misc/localisation'
import {QueryKeys} from '../services/QueryKeys'
import {getSpeechesByEvaluator, getSpeechesByUser} from '../services/speech'
import useQueryUser from '../services/useQueryUser'
import {signOut} from '../services/user'
import {selectIsUploadInProgress} from '../store/reducer/uploadSpeechSlice'
import {TSpeech} from '../types/types'

const cardSize = 300
const gap = 8
const maxWidth = 1200

export default function ProfileScreen() {
  const {data: user, isLoading} = useQueryUser()

  const isUploadInProgress = useSelector(selectIsUploadInProgress)
  const {toggleColorMode} = useColorMode()

  const ToggleThemeIcon = () =>
    useColorModeValue(
      <SunIcon title={LOCAL.SETTINGS.SET_LIGHT_MODE} />,
      <MoonIcon title={LOCAL.SETTINGS.SET_DARK_MODE} />
    )

  const name = `${user?.firstName} ${user?.lastName}`
  const windowWidth = useWindowDimensions().width
  const numColumns = useMemo(
    () => Math.floor(Math.min(windowWidth, maxWidth) / (cardSize + gap)),
    [windowWidth]
  )

  const renderItem = useCallback(
    ({item}: {item: TSpeech}) => {
      return (
        <SpeechCard
          key={item.id}
          speakerView
          mr={gap}
          w={numColumns > 1 ? cardSize : 'full'}
          h={cardSize}
          speech={item}
        />
      )
    },

    [numColumns]
  )

  const Separator = () => <Box w={gap} h={gap} />

  const {data: speeches} = useQuery<TSpeech[]>(
    [QueryKeys.SpeechesByUser, user?.id],
    () => (user?.id ? getSpeechesByUser(user?.id) : [])
  )
  const {data: evaluations} = useQuery<TSpeech[]>(
    [QueryKeys.SpeechesByEvaluator, user?.id],
    () => (user?.id ? getSpeechesByEvaluator(user?.id) : [])
  )

  const [activeTab, setActiveTab] = useState<TTabs>('speeches')

  const listData = useMemo(
    () => (activeTab === 'speeches' ? speeches : evaluations),
    [activeTab, evaluations, speeches]
  )
  const Header = useMemo(
    () =>
      user ? (
        <Column w='full' mb={gap} alignItems='flex-start' space={4}>
          <Row space='4' alignItems='center'>
            <Portrait name={name} uri={user.portrait} />
            <Heading size='sm'>{name}</Heading>
          </Row>
          <Text>{user.biography ?? 'Tell us something about you ...'}</Text>
          <Container w='full' mb='4' position='relative'>
            <Pressable
              position='absolute'
              top='0'
              right='0'
              mb='4'
              onPress={toggleColorMode}
            >
              <ToggleThemeIcon />
            </Pressable>
            <Text>{user.firstName + ' ' + user.lastName}</Text>
            <Text>{user.email}</Text>
          </Container>
          <ResetPassword email={user.email} />
          <Button colorScheme='muted' onPress={signOut}>
            {LOCAL.AUTH.SIGN_OUT}
          </Button>
          <Row w='full'>
            <TabButton
              icon={{
                as: Ionicons,
                name: 'mic-outline'
              }}
              onPress={() => setActiveTab('speeches')}
              active={activeTab === 'speeches'}
            />
            <TabButton
              icon={{
                as: FontAwesome5,
                name: 'star-half-alt'
              }}
              onPress={() => setActiveTab('evaluations')}
              active={activeTab === 'evaluations'}
            />
          </Row>
        </Column>
      ) : (
        <></>
      ),
    [activeTab, name, toggleColorMode, user]
  )
  return (
    <Page>
      <Center h='full' p='4'>
        {isLoading || !user ? (
          <Spinner />
        ) : (
          <Box h='full' w='full' maxW={1200}>
            <FlatList
              w='full'
              showsVerticalScrollIndicator={false}
              ListHeaderComponent={Header}
              key={activeTab + numColumns}
              numColumns={Math.max(numColumns, 1)}
              ItemSeparatorComponent={Separator}
              data={listData}
              renderItem={renderItem}
              keyExtractor={(item, index) => item.id + index}
            />
          </Box>
        )}
      </Center>
      <>{isUploadInProgress && <UploadSpeechModal />}</>
    </Page>
  )
}

type TTabs = 'speeches' | 'evaluations'
