import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {
  Column,
  Container,
  Heading,
  Row,
  Text,
  Pressable,
  Box
} from 'native-base'
import React from 'react'

import useSpeech from '../../hooks/useSpeech'
import LOCAL from '../../misc/localisation'
import {StageParamList} from '../../navigation/types'
import {TSpeechThumbnails} from '../../types/types'
import Thumbnail from '../ui/Thumbnail'

export default function PrimeSpeech() {
  const navigation = useNavigation<StackNavigationProp<StageParamList>>()

  const {speech} = useSpeech('prime')
  const thumbnail = (speech?.thumbnails as TSpeechThumbnails)?.find(
    thumbnail => thumbnail.preview
  )?.url

  return speech ? (
    <Pressable
      position='relative'
      width='full'
      height={['400', '400', '600']}
      background='black'
      maxHeight='100%'
      onPress={() => navigation.navigate('Speech', {id: speech.id})}
    >
      <Thumbnail
        position='absolute'
        top='0'
        left='0'
        right='0'
        bottom='0'
        uri={thumbnail}
        blurRadius={12}
        resizeMode='cover'
      />
      <Thumbnail
        position='absolute'
        top='0'
        left='0'
        right='0'
        bottom='0'
        uri={thumbnail}
        resizeMode='contain'
        backgroundColor='transparent'
      />
      <Box
        bg={{
          linearGradient: {
            colors: ['transparent', 'muted.900']
          }
        }}
        position='absolute'
        top='0'
        left='0'
        right='0'
        bottom='0'
      />
      <Column
        position='absolute'
        bottom='5'
        left='0'
        right='0'
        h='400'
        justifyContent='flex-end'
        space='4'
      >
        <Container
          pl='5'
          pr='3'
          pt='1'
          pb='1.5'
          alignSelf='flex-start'
          bg='primary.500'
        >
          <Text color='lightText' fontSize='lg' bold>
            {LOCAL.SPEECH.SPEECH_OF_THE_WEEK}
          </Text>
        </Container>
        <Column w='full' px='5' space='2'>
          <Text color='lightText'>{speech.speaker}</Text>
          <Row
            w='full'
            justifyContent='space-between'
            alignItems='flex-end'
            space='2'
          >
            <Heading flexShrink='1' flexGrow='0' size='xl' color='lightText'>
              {speech.title}
            </Heading>
            <Text
              textAlign='right'
              flexGrow='1'
              right='5'
              fontSize='xs'
              color='lightText'
            >
              {speech.duration}
            </Text>
          </Row>
        </Column>
      </Column>
    </Pressable>
  ) : (
    <></>
  )
}
