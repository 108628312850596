import AsyncStorage from '@react-native-async-storage/async-storage'
import {StorageManager, ColorMode} from 'native-base'

import Logger from '../utils/Logger'
export type TColorMode = Exclude<ColorMode, null | undefined>
// Define the colorModeManager,
// here we are using react-native-async-storage (https://react-native-async-storage.github.io/async-storage/)
const colorModeManager: (initial: TColorMode) => StorageManager = initial => ({
  get: async () => {
    try {
      const val = await AsyncStorage.getItem('@color-mode')
      if (!val) return initial
      return val === 'dark' ? 'dark' : 'light'
    } catch (e) {
      Logger.error(e)
      return 'light'
    }
  },
  set: async (value: TColorMode) => {
    try {
      await AsyncStorage.setItem('@color-mode', value)
    } catch (e) {
      console.log(e)
    }
  }
})

export default colorModeManager
