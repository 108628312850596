import {Button, Column, FormControl, Input} from 'native-base'
import React, {useContext} from 'react'
import {NativeSyntheticEvent, TextInputKeyPressEventData} from 'react-native'

import useToast from '../../hooks/useToast'
import {ErrorCodes} from '../../misc/ErrorCodes'
import LOCAL from '../../misc/localisation'
import {checkEmail} from '../../services/user'
import Logger from '../../utils/Logger'
import {AuthContext} from './authContext'
import {UserAuthActions} from './authStateMachine'

export default function AuthStart() {
  const {
    isLoading,
    setLoading,
    form: {email},
    handleChange,
    send
  } = useContext(AuthContext)

  const showToast = useToast()

  const handleKeyPress = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>
    // @ts-ignore
  ) => (event as KeyboardEvent).key === 'Enter' && handleSubmit()

  const handleSubmit = () => {
    setLoading(true)
    checkEmail(email)
      .then(res => {
        send(res.length ? UserAuthActions.SIGN_IN : UserAuthActions.SIGN_UP)
      })
      .catch(error => {
        Logger.debug(error)
        switch (error.code) {
          case ErrorCodes.AuthInvalidMail:
            showToast(LOCAL.AUTH.EMAIL_INVALID, 'error')
            break
          default:
            showToast(LOCAL.SOMETHING_WENT_WRONG, 'error')
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <Column space='1' w='full' alignItems='center'>
      <FormControl>
        <Input
          isRequired
          alignSelf='center'
          w='full'
          maxW={200}
          autoFocus
          placeholder={LOCAL.AUTH.EMAIL}
          value={email}
          onChangeText={handleChange('email')}
          onKeyPress={handleKeyPress}
        />
      </FormControl>
      <Button
        onPress={
          email.length
            ? handleSubmit
            : () => showToast(LOCAL.AUTH.EMAIL_MISSING, 'warning')
        }
        colorScheme='primary'
        isDisabled={isLoading}
      >
        {LOCAL.AUTH.GET_STARTED}
      </Button>
    </Column>
  )
}
