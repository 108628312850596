import {Button, Column, FormControl, Input} from 'native-base'
import React, {useCallback, useContext, useState} from 'react'
import {NativeSyntheticEvent, TextInputKeyPressEventData} from 'react-native'

import useToast from '../../hooks/useToast'
import {ErrorCodes} from '../../misc/ErrorCodes'
import LOCAL from '../../misc/localisation'
import {signInUser} from '../../services/user'
import Logger from '../../utils/Logger'
import ResetPassword from './ResetPassword'
import {AuthContext} from './authContext'
import {UserAuthActions} from './authStateMachine'

export default function SignIn() {
  const {
    setLoading,
    isLoading,
    form: {email, password},
    handleChange,
    send
  } = useContext(AuthContext)

  const showToast = useToast()

  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [showSignUpButton, setShowSignUpButton] = useState(false)

  const [wrongPasswordSubmit, setWrongPasswordSubmit] = useState(0)

  const handleSubmit = useCallback(() => {
    setLoading(true)
    signInUser({email, password})
      .catch(error => {
        Logger.debug(error)
        switch (error.code) {
          case ErrorCodes.AuthWrongPassword:
            setWrongPasswordSubmit(number => number + 1)
            if (wrongPasswordSubmit > 1) {
              setShowPasswordReset(true)
              showToast(LOCAL.AUTH.PASSWORD_FORGOTTEN_Q, 'error')
            } else {
              showToast(LOCAL.AUTH.PASSWORD_WRONG, 'error')
            }
            break
          case ErrorCodes.AuthTooManyRequests:
            setShowPasswordReset(true)
            showToast(LOCAL.AUTH.TOO_MANY_REQUESTS, 'error')
            break
          case ErrorCodes.AuthUserNotFound:
            setShowSignUpButton(true)
            showToast(LOCAL.AUTH.USER_NOT_FOUND, 'error')
            break
          default:
            showToast(LOCAL.SOMETHING_WENT_WRONG, 'error')
            console.error(error)
        }
      })
      .finally(() => setLoading(false))
  }, [
    email,
    password,
    setLoading,
    setWrongPasswordSubmit,
    showToast,
    wrongPasswordSubmit
  ])

  const handleKeyPress = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      // @ts-ignore
      if ((e as KeyboardEvent).key === 'Enter') {
        handleSubmit()
      }
    },
    [handleSubmit]
  )

  const isFormComplete = email.length > 0 && password.length > 0

  // TODO: Improve interface for showSignUpButton case. Also, rethink names for showSignUpButton and showPasswordReset
  return (
    <Column space='1' w='full' maxW={200}>
      <FormControl>
        <Input
          isRequired
          placeholder={LOCAL.AUTH.EMAIL}
          value={email}
          onChangeText={handleChange('email')}
        />
      </FormControl>
      <FormControl>
        <Input
          type='password'
          isRequired
          autoFocus
          placeholder={LOCAL.AUTH.PASSWORD}
          value={password}
          onChangeText={handleChange('password')}
          secureTextEntry
          onKeyPress={handleKeyPress}
        />
      </FormControl>
      {showSignUpButton && (
        <Button
          onPress={() => send(UserAuthActions.SIGN_UP)}
          colorScheme='primary'
          isDisabled={isLoading}
        >
          {LOCAL.AUTH.SIGN_UP}
        </Button>
      )}
      {showPasswordReset && <ResetPassword email={email} />}
      <Button.Group w='full'>
        <Button
          flex='1'
          // TODO: Clear form on cancel?
          onPress={() => send(UserAuthActions.CANCEL)}
          colorScheme='light'
          variant='outline'
        >
          {LOCAL.CANCEL}
        </Button>
        <Button
          flex='1'
          onPress={handleSubmit}
          isDisabled={isLoading || !isFormComplete}
        >
          {LOCAL.AUTH.SIGN_IN}
        </Button>
      </Button.Group>
    </Column>
  )
}
