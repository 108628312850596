import 'react-native-get-random-values'
import {deleteObject, getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import {v4 as uuid} from 'uuid'

import {storage} from './firebase'

export async function uploadImageAsync(
  path: string,
  uri: string
): Promise<string> {
  return new Promise(async (resolve, reject) => {
    const blob = await new Promise<Blob>((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        resolve(xhr.response)
      }
      xhr.onerror = function (e) {
        console.error(e)
        reject(new Error('Network request failed'))
      }
      xhr.responseType = 'blob'
      xhr.open('GET', uri, true)
      xhr.send(null)
    })

    const metadata = {
      contentType: blob.type
    }

    const mime = blob.type.split('/')[1]
    const id = uuid()
    const fileName = `${id}.${mime}`

    console.debug('Created blob from uri with mime type: ', mime)

    const fileRef = ref(storage, `${path}/${fileName}`)
    if (fileRef) {
      const result = await uploadBytes(fileRef, blob, metadata)
      console.debug('Created blob from uri with mime type: ', mime)

      if (result.metadata.size > 0) {
        const url = await getDownloadURL(fileRef)
        console.debug(`File '${fileName}' successfully uploaded to: ${url}`)
        resolve(url)
      }
    }

    reject(new Error('Could not upload image.'))
  })
}

export async function deleteFile(url: string): Promise<void> {
  const httpsReference = ref(storage, url)
  return deleteObject(httpsReference)
}
