import * as FileSystem from 'expo-file-system'
import * as ImagePicker from 'expo-image-picker'
import {Platform} from 'expo-modules-core'

import {MAX_VIDEO_DURATION_IN_MIN} from '../constants/general'

export type TVideoFile = {
  lastModified?: number
  name: string
  /** File size in bytes. */
  size?: number
  /** Video duration in seconds. */
  duration?: number
  file?: File
  mimeType?: string
  uri: string
}

/**
 * Picks a video file.
 */
export default class FilePicker {
  /**
   * Picks a file
   * @returns TVideoFile
   */
  public static pickVideo(): Promise<TVideoFile | null> {
    if (Platform.OS === 'web') {
      return this.pickVideoWeb()
    } else {
      return this.pickVideoMobile()
    }
  }

  private static pickVideoMobile(): Promise<TVideoFile | null> {
    return new Promise(res => {
      ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Videos,
        base64: false,
        videoMaxDuration: MAX_VIDEO_DURATION_IN_MIN * 60
      })
        .then(video => {
          if (video.cancelled) {
            res(null)
          } else {
            FileSystem.getInfoAsync(video.uri)
              .then(info => {
                const file = {
                  mimeType: '',
                  ...info,
                  ...video,
                  name: video.uri.split('/').slice(-1)[0]
                }

                file.duration = file.duration
                  ? Number((file.duration / 1000).toFixed(0))
                  : undefined
                file.mimeType = file.mimeType.length
                  ? file.mimeType
                  : file.type + '/' + file.uri.split('.').slice(-1)

                res(file)
              })
              .catch(() => res(null))
          }
        })
        .catch(() => res(null))
    })
  }

  private static pickVideoWeb(): Promise<TVideoFile | null> {
    return new Promise(res => {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'video/*'
      input.multiple = false

      input.addEventListener('change', handleFiles, false)
      function handleFiles(e: Event) {
        const _file = (e.target as HTMLInputElement).files?.[0]
        if (_file === undefined) {
          res(null)
        } else {
          const {name, size, lastModified, type} = _file
          const url = URL.createObjectURL(_file)
          const file: TVideoFile = {
            file: _file,
            uri: url,
            mimeType: type,
            lastModified,
            name,
            size
          }
          res(file)
        }
      }

      window.addEventListener(
        'focus',
        () => {
          setTimeout(() => {
            if (!input.files?.length) {
              res(null)
            }
          }, 200)
        },
        {once: true}
      )

      input.click()
    })
  }
}
