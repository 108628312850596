/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer
} from '@react-navigation/native'
import {createNativeStackNavigator} from '@react-navigation/native-stack'
import {Center, Heading, useColorMode} from 'native-base'
import * as React from 'react'

import Page from '../components/ui/Page'
import useAuthentication, {AuthState} from '../hooks/useAuthentication'
import LOCAL from '../misc/localisation'
import AuthenticationScreen from '../screens/AuthenticationScreen'
import NotFoundScreen from '../screens/NotFoundScreen'
import BottomTabNavigator from './BottomTabNavigator'
import LinkingConfiguration from './LinkingConfiguration'
import {RootStackParamList} from './types'

export default function Navigation() {
  const {colorMode} = useColorMode()
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorMode === 'light' ? DefaultTheme : DarkTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  )
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createNativeStackNavigator<RootStackParamList>()

function RootNavigator() {
  const state = useAuthentication()

  // TODO: Add another state, where authentication state cannot be retrieved,
  // ... maybe because the user has no more internet. Then we should show a specific screen.
  // TODO: Would it be possible to have a smooth transition from StartUpScreen to RootScreen?
  if (state.value === AuthState.Unknown) {
    return <StartUpScreen />
  }
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      {state.value === AuthState.SignedIn && (
        <>
          <Stack.Screen
            name='Root'
            component={BottomTabNavigator}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name='NotFound'
            component={NotFoundScreen}
            options={{title: 'Oops!'}}
          />
        </>
      )}
      {state.value === AuthState.NotSignedIn && (
        <Stack.Screen name='Authentication' component={AuthenticationScreen} />
      )}
    </Stack.Navigator>
  )
}

function StartUpScreen() {
  return (
    <Page>
      <Center w='full' h='full'>
        <Heading>{LOCAL.WELCOME_TO_ZPEEKY}</Heading>
      </Center>
    </Page>
  )
}
