import {Modal, IModalProps, IBoxProps} from 'native-base'
import React from 'react'

export default function BaseModal({
  isOpen,
  closeModal,
  children,
  width = 'md',
  ...props
}: {
  isOpen: boolean
  closeModal: () => void
  children?: any
  width?: IBoxProps<IModalProps>['width']
} & IModalProps): JSX.Element {
  return (
    <Modal {...props} isOpen={isOpen} onClose={closeModal}>
      <Modal.Content width={width} maxWidth='full'>
        <Modal.CloseButton />
        <Modal.Body>{children}</Modal.Body>
      </Modal.Content>
    </Modal>
  )
}
