import {useEffect, useMemo} from 'react'
import {useQuery, UseQueryResult} from 'react-query'
import {useSelector} from 'react-redux'

import {REFETCH_INTERVALL} from '../constants/general'
import {QueryKeys} from '../services/QueryKeys'
import {getUser} from '../services/user'
import {selectUserId} from '../store/reducer/userSlice'
import {TUser} from '../types/types'

export default function useQueryUser(): UseQueryResult<TUser, unknown> {
  const id = useSelector(selectUserId)

  const result = useQuery<TUser>([QueryKeys.User, id], () => getUser(id), {
    refetchInterval: REFETCH_INTERVALL,
    enabled: false
  })

  const refetch = useMemo(() => result.refetch, [result.refetch])

  if (result.data) {
    // TODO: This needs to be harmonized in all dbs.
    // @ts-ignore
    result.data.id = result.data.id ?? result.data.uid ?? result.data._id
  }

  useEffect(() => {
    id && refetch()
  }, [id, refetch])

  return result
}
