import React, {Dispatch, SetStateAction} from 'react'

export const initialAuthForm = {
  email: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
  timesWrongPassword: 0,
  termsOfUse: false,
  privacyPolicy: false
}

type TAuthContextType = {
  isLoading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  form: typeof initialAuthForm
  setForm: Dispatch<SetStateAction<typeof initialAuthForm>>
  handleChange: (
    key: keyof typeof initialAuthForm
  ) => (value: string | boolean) => void
  send: Function
}

const initialAuthContext = {
  isLoading: false,
  setLoading: () => {},
  handleChange: key => {
    return () => {}
  },
  setForm: () => {},
  form: {} as typeof initialAuthForm,
  send: () => {}
} as TAuthContextType

export const AuthContext = React.createContext(initialAuthContext)
