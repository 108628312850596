import {startCase, uniq} from 'lodash'
import {
  Column,
  FormControl,
  Heading,
  Input,
  ScrollView,
  Text,
  TextArea,
  Button
} from 'native-base'
import React, {useCallback} from 'react'
import {GestureResponderEvent} from 'react-native'
import {useQuery, UseQueryResult} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'

import {CATEGORY} from '../../constants/Categories'
import countries from '../../misc/countries'
import languagesMap from '../../misc/languages'
import LOCAL from '../../misc/localisation'
import {QueryKeys} from '../../services/QueryKeys'
import {getSpeeches} from '../../services/speech'
import useQueryUser from '../../services/useQueryUser'
import {uploadSpeechAction} from '../../store/middleware/uploadSpeechMiddleware'
import {
  selectUploadSpeechForm,
  initialForm,
  updateForm,
  FormStates,
  selectIsSpeechComplete,
  resetForm,
  TUploadSpeech,
  changeState
} from '../../store/reducer/uploadSpeechSlice'
import {TSpeechThumbnails, TUser} from '../../types/types'
import Logger from '../../utils/Logger'
import {Select} from '../ui/Select'
import {VideoFileInput, TFile} from '../ui/VideoFileInput'

export default function UploadSpeech({
  closeModal,
  goToSpeech
}: {
  closeModal: () => void
  goToSpeech: (id: string) => void
}) {
  const form = useSelector(selectUploadSpeechForm) as TUploadSpeech
  const isComplete = useSelector(selectIsSpeechComplete)
  const {data: user, isLoading} = useQueryUser()

  const dispatch = useDispatch()

  const handleChange = useCallback(
    (key: keyof typeof initialForm) =>
      (text: string | TFile | TSpeechThumbnails) =>
        dispatch(updateForm({[key]: text})),
    [dispatch]
  )

  const {refetch} = useRefetchSpeeches()

  const handleSubmit = useCallback(
    () =>
      dispatch(
        uploadSpeechAction({
          user: user as TUser,
          callback: () => {
            Logger.debug('Refetching speeches after uploading a speech.')
            refetch()
          }
        })
      ),
    [dispatch, refetch, user]
  )

  return isLoading ? (
    <></>
  ) : (
    <ScrollView>
      <Column py='1' space='4'>
        <Heading textAlign='center' fontSize='xl'>
          {LOCAL.SPEECH.UPLOAD_SPEECH}
        </Heading>
        {form.state === FormStates.Editing && (
          <EditSpeech
            form={form}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isComplete={isComplete}
          />
        )}
        {form.state === FormStates.Uploading && (
          <UploadProgress progress={form.upload.progress} />
        )}
        {form.state === FormStates.Uploaded && (
          <UploadResult
            handleGoToSpeech={() => {
              closeModal()
              form.id && goToSpeech(form.id)
            }}
            handleReset={() => dispatch(resetForm())}
          />
        )}
        {form.state === FormStates.Error && (
          <UploadError
            handleReset={() => dispatch(changeState(FormStates.Editing))}
          />
        )}
      </Column>
    </ScrollView>
  )
}

function useRefetchSpeeches(): UseQueryResult<unknown, unknown> {
  const queryResult = useQuery(QueryKeys.Speeches, () => getSpeeches(), {
    enabled: false
  })
  return queryResult
}

function EditSpeech({
  form,
  handleChange,
  handleSubmit,
  isComplete
}: {
  form: TUploadSpeech
  handleChange: (
    key: keyof typeof initialForm
  ) => (value: string | TFile | TSpeechThumbnails) => void
  handleSubmit: (event: GestureResponderEvent) => void
  isComplete: boolean
}) {
  return (
    <Column space='4'>
      <FormControl>
        <FormControl.Label>{LOCAL.SPEECH.TITLE} *</FormControl.Label>
        <Input
          placeholder={LOCAL.SPEECH.TITLE}
          onChangeText={handleChange('title')}
        />
      </FormControl>
      <VideoFileInput
        file={form.file}
        setFile={handleChange('file')}
        setThumbnails={handleChange('thumbnails')}
      />
      <FormControl>
        <FormControl.Label>{LOCAL.SPEECH.DESCRIPTION} *</FormControl.Label>
        <TextArea
          placeholder={LOCAL.SPEECH.DESCRIPTION}
          value={form.description}
          onChangeText={handleChange('description')}
          minH='48'
        />
      </FormControl>
      <Select
        key={LOCAL.SPEECH.CATEGORY}
        label={LOCAL.SPEECH.CATEGORY}
        items={categories}
        initialValue={startCase(form.category)}
        onSelect={handleChange('category')}
      />
      <Select
        key={LOCAL.SPEECH.LANGUAGE}
        label={LOCAL.SPEECH.LANGUAGE}
        items={languagesInternationalNames}
        initialValue={form.language}
        onSelect={handleChange('language')}
      />
      <Select
        key={LOCAL.SPEECH.COUNTRY}
        label={LOCAL.SPEECH.COUNTRY}
        items={countryNames}
        initialValue={form.country}
        onSelect={handleChange('country')}
      />
      <Button onPress={handleSubmit} isDisabled={!isComplete}>
        {LOCAL.SPEECH.UPLOAD_SPEECH}
      </Button>
    </Column>
  )
}

function UploadProgress({progress}: {progress: number}) {
  return (
    <Column space='4'>
      <Text>{LOCAL.SPEECH.UPLOADING_SPEECH}</Text>
      <Input isDisabled value={progress + '%'} />
      {/* <Button
        onPress={() => {
          fileUpload.cancel()
          send(FormStatesEvents.SUBMIT)
        }}
        status='danger'
        style={{marginBottom: 12, marginRight: 12}}
        >
        {__.CANCEL}
        </Button> */}
    </Column>
  )
}

function UploadResult({
  handleGoToSpeech,
  handleReset
}: {
  handleGoToSpeech: (event: GestureResponderEvent) => void
  handleReset: (event: GestureResponderEvent) => void
}) {
  return (
    <Column space='4'>
      <Text>{LOCAL.SPEECH.UPLOADED_SPEECH}</Text>
      <Button onPress={handleGoToSpeech}>{LOCAL.SPEECH.VIEW_SPEECH}</Button>
      <Button onPress={handleReset} colorScheme='light'>
        {LOCAL.SPEECH.UPLOAD_ANOTHER_SPEECH}
      </Button>
    </Column>
  )
}

function UploadError({
  handleReset
}: {
  handleReset: (event: GestureResponderEvent) => void
}) {
  return (
    <Column space='4'>
      <Text>{LOCAL.SPEECH.UPLOADING_SPEECH_ERROR}</Text>
      <Button onPress={handleReset} colorScheme='light'>
        {LOCAL.NAVIGATION.BACK}
      </Button>
    </Column>
  )
}

const categories = Object.keys(CATEGORY).map(key =>
  startCase(key.toLowerCase())
)

const languages = uniq(Object.values(languagesMap))

const languagesInternationalNames = uniq(languages.map(lang => lang.name))

const countryNames = countries.map(c => c.name)
