import {FormControl, Select as _Select, StyledProps} from 'native-base'
import React, {useState} from 'react'

export function Select({
  label,
  items,
  initialValue,
  onSelect,
  ...props
}: {
  label?: string
  items: string[]
  initialValue: string
  onSelect: (value: string) => void
} & StyledProps) {
  const [selectedValue, setSelectedValue] = useState(initialValue)

  return (
    <FormControl {...props}>
      {label && <FormControl.Label>{label}</FormControl.Label>}
      <_Select
        selectedValue={selectedValue}
        onValueChange={value => {
          setSelectedValue(value)
          onSelect(value)
        }}
      >
        {items.map(item => (
          <_Select.Item label={item} value={item} key={item} />
        ))}
      </_Select>
    </FormControl>
  )
}
