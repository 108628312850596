export default class Logger {
  private static active: boolean = process.env.NODE_ENV === 'development'
  private static ignoreList = [
    'Generating a thumbnail from a video is not supported on web.'
  ]

  private static shouldBeIgnored(...msg: any) {
    if (
      [...msg].find(m =>
        this.ignoreList.find(entry => String(m).includes(entry))
      )
    ) {
      return true
    } else return false
  }

  /**
   * debug
   */
  public static debug(...msg: any) {
    if (!this.active) return
    if (this.shouldBeIgnored(msg)) return
    console.debug(...msg)
  }
  /**
   * error
   */
  public static error(...msg: any) {
    if (this.shouldBeIgnored(msg)) return
    console.error(...msg)
  }
  /**
   * log
   */
  public static log(...msg: any) {
    if (!this.active) return
    if (this.shouldBeIgnored(msg)) return
    this.active && console.log(...msg)
  }
}
