import {Box, StyledProps} from 'native-base'
import {SafeAreaProps} from 'native-base/lib/typescript/components/types'
import {ReactNode} from 'react'

export default function ThemedBox({
  children,
  ...props
}: StyledProps & SafeAreaProps & {children: ReactNode}) {
  return (
    <Box
      _dark={{
        backgroundColor: 'light.900'
      }}
      _light={{
        backgroundColor: 'light.50'
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
