import {createMachine} from 'xstate'

export enum UserAuthState {
  Start = 'Start',
  New = 'New',
  Registered = 'Registered'
}

export enum UserAuthActions {
  CANCEL = 'CANCEL',
  SIGN_UP = 'SIGN_UP',
  SIGN_IN = 'SIGN_IN'
}

const authMachine = createMachine({
  id: 'auth',
  initial: UserAuthState.Start,
  states: {
    [UserAuthState.Start]: {
      on: {
        [UserAuthActions.SIGN_UP]: UserAuthState.New,
        [UserAuthActions.SIGN_IN]: UserAuthState.Registered
      }
    },
    [UserAuthState.New]: {
      on: {
        [UserAuthActions.SIGN_IN]: UserAuthState.Registered,
        [UserAuthActions.CANCEL]: UserAuthState.Start
      }
    },
    [UserAuthState.Registered]: {
      on: {
        [UserAuthActions.SIGN_UP]: UserAuthState.New,
        [UserAuthActions.CANCEL]: UserAuthState.Start
      }
    }
  }
})

export default authMachine
