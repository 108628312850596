import React, {useMemo} from 'react'
import {GestureResponderEvent} from 'react-native'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'

import LOCAL from '../../misc/localisation'
import {QueryKeys} from '../../services/QueryKeys'
import {getEvaluations} from '../../services/evaluation'
import {selectUserId} from '../../store/reducer/userSlice'
import LabeledIcon from '../ui/LabeledIcon'

export default function EvaluationButton({
  id,
  onPress
}: {
  id: string
  onPress: (event: GestureResponderEvent) => void
}) {
  const {
    isLoading,
    error,
    data: evaluations
  } = useQuery([QueryKeys.Evaluations, id], () => getEvaluations(id))

  const userId = useSelector(selectUserId)

  const userHasEvaluated = useMemo(
    () =>
      (evaluations?.findIndex(
        evaluation => evaluation.evaluator.id === userId
      ) ?? -1) >= 0,
    [evaluations, userId]
  )

  const hideButton = userHasEvaluated || isLoading || error

  return hideButton ? (
    <></>
  ) : (
    <LabeledIcon
      onPress={onPress}
      label={LOCAL.SPEECH.EVALUATE}
      name='thumbs-up'
    />
  )
}
