import {MaterialIcons} from '@expo/vector-icons'
import {Icon, Avatar, useColorModeValue} from 'native-base'
import React from 'react'

export default function Portrait({
  uri,
  name,
  onPick
}: {
  uri: string | undefined
  name: string
  onPick?: () => void
}) {
  const bg = useColorModeValue('lightText', 'darkText')
  return (
    <Avatar
      bg='coolGray.800:alpha.20'
      size='2xl'
      source={{
        uri
      }}
    >
      {name}
      {onPick && (
        <Avatar.Badge
          bg={bg}
          boxSize='10'
          alignItems='center'
          justifyContent='center'
        >
          <Icon
            size='sm'
            as={MaterialIcons}
            name='add-a-photo'
            onPress={onPick}
          />
        </Avatar.Badge>
      )}
    </Avatar>
  )
}
