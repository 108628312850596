import {Ionicons} from '@expo/vector-icons'
import {useNavigation} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {Fab, Icon} from 'native-base'
import React from 'react'

import useModal from '../../hooks/useModal'
import {StageParamList} from '../../navigation/types'
import BaseModal from '../ui/BaseModal'
import UploadSpeech from './UploadSpeech'

export default function UploadSpeechModal() {
  const {isOpen, showModal, closeModal} = useModal()
  const navigation = useNavigation<StackNavigationProp<StageParamList>>()

  const goToSpeech = (id: string) => navigation.navigate('Speech', {id})

  return (
    <>
      <Fab
        renderInPortal={false}
        bottom='4'
        right='4'
        icon={<Icon size='sm' as={Ionicons} name='ios-videocam' />}
        onPress={showModal}
        colorScheme='red'
      />
      <BaseModal isOpen={isOpen} closeModal={closeModal}>
        <UploadSpeech closeModal={closeModal} goToSpeech={goToSpeech} />
      </BaseModal>
    </>
  )
}
