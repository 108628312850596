import {useMemo} from 'react'
import {useColorScheme} from 'react-native'

import colorModeManager, {TColorMode} from '../theme/colorModeManager'

export default function useColorModeManager() {
  const initialColorScheme = useColorScheme()
  const _colorModeManager = useMemo(
    () => colorModeManager(initialColorScheme as TColorMode),
    [initialColorScheme]
  )
  return _colorModeManager
}
