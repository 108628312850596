import {startCase} from 'lodash'
import {Box, Column, FlatList, Heading} from 'native-base'
import React from 'react'
import {Platform} from 'react-native'

import {TSpeech} from '../../types/types'
import SpeechCard from './SpeechCard'

export default function SpeechSlider({
  speeches,
  category
}: {
  speeches: TSpeech[]
  category?: string
}) {
  const renderItem = ({item}: {item: TSpeech}) => {
    return <SpeechCard speech={item} />
  }

  const Separator = () => <Box w='4' />
  return speeches.length === 0 ? (
    <></>
  ) : (
    <Column space='2' pl='6'>
      {category && (
        <Heading pl='3' size='md'>
          {startCase(category)}
        </Heading>
      )}
      <FlatList
        showsHorizontalScrollIndicator={Platform.OS === 'web'}
        horizontal
        ItemSeparatorComponent={Separator}
        data={speeches}
        renderItem={renderItem}
        keyExtractor={(item, index) => item.id + index}
      />
    </Column>
  )
}
