import {isString} from 'lodash'
import {Column, ScrollView, Text} from 'native-base'
import React, {Fragment, useMemo} from 'react'
import {useQuery} from 'react-query'

import FocusSpeech from '../components/stage/FocusSpeech'
import PrimeSpeech from '../components/stage/PrimeSpeech'
import SpeechSlider from '../components/stage/SpeechSlider'
import UploadSpeechModal from '../components/stage/UploadSpeechModal'
import Page from '../components/ui/Page'
import {CATEGORY} from '../constants/Categories'
import {filterSpeechByCategory} from '../helpers/speech'
import LOCAL from '../misc/localisation'
import {QueryKeys} from '../services/QueryKeys'
import {getSpeeches} from '../services/speech'

const categories = Object.values(CATEGORY).map(c => c.toLowerCase())

export default function StageScreen() {
  const {isLoading, error, data} = useQuery(QueryKeys.Speeches, () =>
    getSpeeches()
  )

  const speeches = useMemo(
    () => data?.filter(speech => isString(speech.file.url)),
    [data]
  )

  return (
    <Page>
      <ScrollView showsVerticalScrollIndicator={false}>
        {!speeches || error || isLoading ? (
          <Text>{LOCAL.LOADING}</Text>
        ) : (
          <Column mb='6'>
            <Column space='lg'>
              <PrimeSpeech />
              {categories.map((category, index) =>
                index === 2 ? (
                  <Fragment key={'focusSpeech with ' + category}>
                    <SpeechSlider
                      speeches={filterSpeechByCategory(speeches, category)}
                      category={category}
                      key={category}
                    />
                    <FocusSpeech key='focusSpeech' />
                  </Fragment>
                ) : (
                  <SpeechSlider
                    speeches={filterSpeechByCategory(speeches, category)}
                    category={category}
                    key={category}
                  />
                )
              )}
            </Column>
          </Column>
        )}
      </ScrollView>
      <UploadSpeechModal />
    </Page>
  )
}
