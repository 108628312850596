import {FontAwesome} from '@expo/vector-icons'
import {Column, Icon, IconButton, Row, Text} from 'native-base'
import {IVStackProps} from 'native-base/lib/typescript/components/primitives/Stack/VStack'
import React, {useCallback} from 'react'

import LOCAL from '../../misc/localisation'
import {TEvaluationDimensions} from '../../services/evaluation'

const dimensionTextMap: Record<keyof TEvaluationDimensions, string> = {
  comprehensibilty: LOCAL.EVALUATION.COMPREHENSIBILITY,
  credibility: LOCAL.EVALUATION.CREDIBILITY,
  bodyLanguage: LOCAL.EVALUATION.BODY_LANGUAGE,
  vocalQuality: LOCAL.EVALUATION.VOCAL_QUALITY,
  stylisticDevices: LOCAL.EVALUATION.STYLISTIC_DEVICES
}

export function Dimension({
  dimension,
  dimensionValue,
  setDimensionValue,
  disabled,
  ...props
}: {
  dimension: keyof TEvaluationDimensions
  dimensionValue: number
  setDimensionValue?: (dimension: string, value: number) => void
  disabled?: boolean
} & IVStackProps) {
  const updateValue = useCallback(
    (value: number) => {
      if (disabled) return
      setDimensionValue?.(dimension, value)
    },
    [dimension, disabled, setDimensionValue]
  )

  return (
    <Column space='1' w='full' maxW={300} {...props}>
      <Text>{dimensionTextMap[dimension]}</Text>
      <Row justifyContent='space-between' w='full'>
        {new Array(5).fill(1).map((_, index) => (
          <IconButton
            key={index}
            isDisabled={disabled}
            _hover={{
              bg: disabled ? 'none' : 'yellow.100'
            }}
            onPress={() => updateValue(index + 1)}
            icon={
              <Icon
                as={FontAwesome}
                color='yellow.400'
                name={dimensionValue > index ? 'star' : 'star-o'}
              />
            }
          />
        ))}
      </Row>
    </Column>
  )
}
