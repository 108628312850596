import {Ionicons} from '@expo/vector-icons'
import {
  Box,
  Button,
  Checkbox,
  Column,
  FormControl,
  Icon,
  IconButton,
  Input,
  Row,
  Text
} from 'native-base'
import React, {useContext, useState} from 'react'
import {NativeSyntheticEvent, TextInputKeyPressEventData} from 'react-native'
import {useDispatch} from 'react-redux'

import useToast from '../../hooks/useToast'
import LOCAL from '../../misc/localisation'
import {addUser, signUpUser} from '../../services/user'
import {setUser} from '../../store/reducer/userSlice'
import Logger from '../../utils/Logger'
import PrivacyPolicy from '../legal/PrivacyPolicy'
import Terms from '../legal/Terms'
import BaseModal from '../ui/BaseModal'
import {AuthContext} from './authContext'
import {UserAuthActions} from './authStateMachine'

export default function SignUp() {
  const dispatch = useDispatch()
  const {
    isLoading,
    setLoading,
    form: {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      privacyPolicy,
      termsOfUse
    },
    handleChange,
    send
  } = useContext(AuthContext)

  const showToast = useToast()
  const [showTermsOfUse, setShowTermsOfUse] = useState(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)

  const handleSubmit = () => {
    setLoading(true)
    signUpUser({email, password})
      .then(res => {
        if (res instanceof Error) {
          throw res
        } else {
          dispatch(
            setUser({
              metadata: {...res.user?.metadata},
              firstName,
              lastName
            })
          )
          addUser({
            firstName,
            lastName,
            ...res
          })
        }
      })
      .catch(e => {
        showToast(LOCAL.SOMETHING_WENT_WRONG, 'error')
        // TODO: Check which specific errors might occur and handle these gracefully.
        Logger.debug(e)
      })
      .finally(() => setLoading(false))
  }

  const handleKeyPress = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    // @ts-ignore
    if ((e as KeyboardEvent).key === 'Enter' && isFormComplete) {
      handleSubmit()
    }
  }

  const isFormComplete =
    firstName.length > 0 &&
    lastName.length > 0 &&
    password.length > 0 &&
    password === confirmPassword &&
    email.length > 0 &&
    termsOfUse &&
    privacyPolicy

  return (
    <Column space='1' w='full' maxW={200} alignItems='stretch'>
      <FormControl>
        <Input
          isRequired
          placeholder={LOCAL.AUTH.EMAIL}
          value={email}
          onChangeText={handleChange('email')}
        />
      </FormControl>
      <FormControl>
        <Input
          isRequired
          placeholder={LOCAL.AUTH.PASSWORD}
          autoFocus
          value={password}
          onChangeText={handleChange('password')}
          secureTextEntry
        />
      </FormControl>
      <FormControl>
        <Input
          isRequired
          placeholder={LOCAL.AUTH.PASSWORD_CONFIRM}
          value={confirmPassword}
          onChangeText={handleChange('confirmPassword')}
          secureTextEntry
        />
      </FormControl>
      <FormControl>
        <Input
          isRequired
          placeholder={LOCAL.USER.FIRST_NAME}
          value={firstName}
          onChangeText={handleChange('firstName')}
        />
      </FormControl>
      <FormControl>
        <Input
          isRequired
          placeholder={LOCAL.USER.LAST_NAME}
          value={lastName}
          onChangeText={handleChange('lastName')}
          onKeyPress={handleKeyPress}
        />
      </FormControl>
      <Column my='2' space='4'>
        <Text>{LOCAL.AUTH.READ_AND_AGREE}</Text>
        <Row alignItems='center'>
          <Box flex='1'>
            <FormControl>
              <Checkbox
                size='sm'
                value={LOCAL.AUTH.TERMS_OF_USE}
                isChecked={termsOfUse}
                onChange={handleChange('termsOfUse')}
              >
                <Text ml='1'>{LOCAL.AUTH.TERMS_OF_USE}</Text>
              </Checkbox>
            </FormControl>
          </Box>
          <IconButton
            _icon={{size: 'sm'}}
            icon={<Icon as={Ionicons} name='information' />}
            borderRadius='full'
            colorScheme='light'
            onPress={() => setShowTermsOfUse(true)}
          />
        </Row>
        <Row alignItems='center'>
          <Box flex='1'>
            <FormControl>
              <Checkbox
                size='sm'
                value={LOCAL.AUTH.PRIVACY_POLICY}
                isChecked={privacyPolicy}
                onChange={handleChange('privacyPolicy')}
              >
                <Text ml='1'>{LOCAL.AUTH.PRIVACY_POLICY}</Text>
              </Checkbox>
            </FormControl>
          </Box>
          <IconButton
            _icon={{size: 'sm'}}
            icon={<Icon as={Ionicons} name='information' />}
            borderRadius='full'
            colorScheme='light'
            onPress={() => setShowPrivacyPolicy(true)}
          />
        </Row>
      </Column>
      <Button.Group w='full'>
        <Button
          flex='1'
          // TODO: Clear form on cancel?
          onPress={() => send(UserAuthActions.CANCEL)}
          colorScheme='light'
          variant='outline'
        >
          {LOCAL.CANCEL}
        </Button>
        <Button
          flex='1'
          onPress={handleSubmit}
          isDisabled={isLoading || !isFormComplete}
        >
          {LOCAL.AUTH.SIGN_UP}
        </Button>
      </Button.Group>
      <BaseModal
        isOpen={showTermsOfUse}
        closeModal={() => setShowTermsOfUse(false)}
      >
        <Terms />
      </BaseModal>
      <BaseModal
        isOpen={showPrivacyPolicy}
        closeModal={() => setShowPrivacyPolicy(false)}
      >
        <PrivacyPolicy />
      </BaseModal>
    </Column>
  )
}
