import {Ionicons} from '@expo/vector-icons'
import {Column, Heading, Icon, IconButton, Row, Text} from 'native-base'
import React from 'react'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'

import {dimensionOrder} from '../../constants/dimensions'
import useModal from '../../hooks/useModal'
import LOCAL from '../../misc/localisation'
import {QueryKeys} from '../../services/QueryKeys'
import {getEvaluation} from '../../services/evaluation'
import {selectUserId} from '../../store/reducer/userSlice'
import {TEvaluation, TSpeech} from '../../types/types'
import {getRelativeTime} from '../../utils/timeUtils'
import BaseModal from '../ui/BaseModal'
import TruncatedText from '../ui/TruncatedText'
import {Dimension} from './Dimension'
import PatchEvaluation from './PatchEvaluation'
import VoteEvaluation from './VoteEvaluation'

export default function Evaluation({
  evaluation: _evaluation,
  speech,
  userEvaluation
}: {
  evaluation: TEvaluation
  speech: TSpeech
  userEvaluation?: boolean
}) {
  const {
    isLoading,
    isFetching,
    data: evaluation,
    refetch
  } = useQuery(
    [QueryKeys.Evaluation, _evaluation.id],
    () => getEvaluation(_evaluation.id),
    {enabled: false, initialData: _evaluation}
  )

  const {speaker} = speech

  const userId = useSelector(selectUserId)

  const evaluator = userEvaluation
    ? LOCAL.EVALUATION.EVALUATION_FROM_ME
    : evaluation?.evaluator.firstName + ' ' + evaluation?.evaluator.lastName
  const borderColor = userEvaluation ? 'primary.500' : 'light.300'
  const {isOpen, showModal, closeModal} = useModal()

  const hasTitle = Number(evaluation?.title?.length) > 0
  const title = hasTitle ? evaluation?.title : evaluator

  const date = evaluation?.date ? getRelativeTime(evaluation?.date) : ''

  return evaluation ? (
    <Column
      position='relative'
      flexShrink='1'
      flexBasis='300'
      flexGrow='1'
      mb='2'
      padding='2'
      borderWidth={1}
      borderColor={borderColor}
      space='md'
    >
      <Column space='2' w='full'>
        <Row space='md' w='full' justifyContent='space-between'>
          <Heading flex='1' isTruncated noOfLines={2} size='md'>
            {title}
          </Heading>
          {userEvaluation && (
            <IconButton
              _icon={{size: 'sm'}}
              icon={<Icon as={Ionicons} name='create-outline' />}
              mt={-2}
              borderRadius='full'
              colorScheme='light'
              onPress={showModal}
            />
          )}
        </Row>
        <Row justifyContent='space-between'>
          {hasTitle && <Text>{evaluator}</Text>}
          <Text>
            {evaluation.edited ? LOCAL.EDITED : ''} {date}
          </Text>
        </Row>
      </Column>
      <Row width='full'>
        <Column p='1' space='2'>
          <VoteEvaluation
            type='up'
            evaluation={evaluation}
            userId={userId}
            isLoading={isLoading || isFetching}
            refetch={refetch}
          />
          <VoteEvaluation
            type='down'
            evaluation={evaluation}
            userId={userId}
            isLoading={isLoading || isFetching}
            refetch={refetch}
          />
          {!userEvaluation && (
            <VoteEvaluation
              type='flag'
              evaluation={evaluation}
              userId={userId}
              isLoading={isLoading || isFetching}
              refetch={refetch}
              showNumber={false}
            />
          )}
        </Column>
        <Column p='2' space='1' flex='1'>
          <Heading size='sm' bold>
            {LOCAL.EVALUATION.PRAISE}
          </Heading>
          <TruncatedText mb='3'>{evaluation.praise}</TruncatedText>
          <Heading size='sm' bold>
            {LOCAL.EVALUATION.SUGGESTIONS(speaker.split(' ')[0])}
          </Heading>
          <TruncatedText mb='3'>{evaluation.suggestions}</TruncatedText>
          {evaluation.dimensions && (
            <>
              <Heading key={evaluation.id + 'dimensions'} size='sm' bold>
                {LOCAL.EVALUATION.DIMENSIONS}
              </Heading>
              {dimensionOrder.map(dimension => (
                <Dimension
                  key={dimension}
                  dimension={dimension}
                  dimensionValue={evaluation.dimensions![dimension]}
                  disabled
                  mb='4'
                />
              ))}
            </>
          )}
        </Column>
      </Row>
      <BaseModal isOpen={isOpen} closeModal={closeModal} size='xl'>
        <PatchEvaluation
          currentEvaluation={evaluation}
          speech={speech}
          closeModal={closeModal}
          refetch={refetch}
        />
      </BaseModal>
    </Column>
  ) : null
}
