import {ResizeMode, Video, VideoProps} from 'expo-av'
import React, {useRef, useState} from 'react'

export default function VideoPlayer({
  uri,
  posterSource,
  ...props
}: {uri: string} & VideoProps) {
  const video = useRef<Video>(null)
  const [isLoaded, setLoaded] = useState(false)

  return (
    <Video
      ref={video}
      posterSource={posterSource}
      usePoster={!isLoaded}
      onPlaybackStatusUpdate={status => setLoaded(status.isLoaded)}
      source={{uri}}
      useNativeControls
      resizeMode={ResizeMode.CONTAIN}
      {...props}
    />
  )
}
