import {configureStore} from '@reduxjs/toolkit'

import {uploadSpeechMiddleware} from './middleware/uploadSpeechMiddleware'
import uploadSpeechReducer from './reducer/uploadSpeechSlice'
import userSlice from './reducer/userSlice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    uploadSpeech: uploadSpeechReducer
  },
  middleware: [uploadSpeechMiddleware]
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
