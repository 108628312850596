import {NODE_ENV} from '@env'
import {useMachine} from '@xstate/react'
import * as SplashScreen from 'expo-splash-screen'
import {NativeBaseProvider} from 'native-base'
import React from 'react'
import {LogBox} from 'react-native'
import {SafeAreaProvider} from 'react-native-safe-area-context'
import {QueryClient, QueryClientProvider} from 'react-query'
import {Provider} from 'react-redux'

import StatusBar from './components/ui/StatusBar'
import uploadMachine from './features/upload/uploadStateMachine'
import useCachedResources from './hooks/useCachedResources'
import useColorModeManager from './hooks/useColorModeManager'
import Navigation from './navigation/Navigation'
import {StateMachineContext} from './stateMachines/stateMachineContext'
import {store} from './store/store'
import config from './theme/nativeBaseConfig'
import theme from './theme/theme'
import Logger from './utils/Logger'

const queryClient = new QueryClient()

SplashScreen.preventAutoHideAsync()
setTimeout(SplashScreen.hideAsync, 7000)

Logger.debug('Mode', NODE_ENV)

export default function App() {
  suppressLogs()

  const isLoadingComplete = useCachedResources()
  const [state, send] = useMachine(uploadMachine)

  const colorModeManager = useColorModeManager()
  if (!isLoadingComplete) {
    return null
  } else {
    return (
      <StateMachineContext.Provider value={{upload: {state, send}}}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <NativeBaseProvider
              config={config}
              theme={theme}
              colorModeManager={colorModeManager}
            >
              <SafeAreaProvider>
                <Navigation />
                <StatusBar />
              </SafeAreaProvider>
            </NativeBaseProvider>
          </Provider>
        </QueryClientProvider>
      </StateMachineContext.Provider>
    )
  }
}

function suppressLogs() {
  LogBox.ignoreLogs(['Setting a timer for a long period of time'])
  LogBox.ignoreLogs([
    'AsyncStorage has been extracted from react-native core and will be removed in a future release.'
  ])
  LogBox.ignoreLogs([
    `Attempted import error: 'createElement' is not exported from 'react-native-web/dist/index'`
  ])
}
