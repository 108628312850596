import {getInfoAsync} from 'expo-file-system'
import {Platform} from 'react-native'

export async function verifyMaxFileSize(
  dataUri: string,
  maxSizeMb: number
): Promise<[boolean, number]> {
  let size
  if (Platform.OS === 'web') {
    const base64str = dataUri.split(',')[1] //remove the image type metadata.
    const file = Buffer.from(base64str, 'base64') //encode image into bytes
    size = file.byteLength
  } else {
    size = (await getInfoAsync(dataUri)).size
  }
  if (!size) {
    console.error('File size is undefined.')
    return [false, 0]
  }
  return [bToMb(size) < maxSizeMb, size]
}

export function bToMb(size: number): number {
  return size / (1000 * 1000)
}
