import {FontAwesome} from '@expo/vector-icons'
import {Column, Icon, IconButton, Text} from 'native-base'
import React, {useCallback, useEffect, useState} from 'react'

import {
  TEvaluation,
  TVote,
  voteEvaluation,
  VoteType
} from '../../services/evaluation'
import Logger from '../../utils/Logger'

export default function EvaluationVote({
  type,
  evaluation,
  userId,
  showNumber = true,
  isLoading: _isLoading,
  refetch
}: {
  type: VoteType
  userId: string
  evaluation: TEvaluation
  showNumber?: boolean
  isLoading: boolean
  refetch: Function
}) {
  const [isLoading, setLoading] = useState(_isLoading)
  useEffect(() => {
    setLoading(_isLoading)
  }, [_isLoading])

  const {
    id,
    evaluator: {id: evaluatorId}
  } = evaluation
  const votes = evaluation[VoteTypeKeyMap[type]]

  const handleVote = useCallback(() => {
    if (evaluatorId === userId) {
      return
    }
    setLoading(true)
    voteEvaluation(id, type)
      .catch(error => Logger.error(error))
      .then(() =>
        Logger.debug(
          `User (${userId}) voted on an evaluation (${id}) with type: ${type}`
        )
      )
      .finally(() => refetch())
  }, [evaluatorId, id, refetch, type, userId])

  const voted = votedByUser(votes, userId)
  const isDisabled = isLoading || evaluatorId === userId

  return (
    <Column alignItems='center'>
      <IconButton
        _icon={{
          size: 'sm',
          _dark: {
            color: 'primary.500'
          },
          _light: {
            color: 'primary.500'
          }
        }}
        icon={
          <Icon
            as={FontAwesome}
            name={
              voted
                ? VoteTypeIconsMap[type].voted
                : VoteTypeIconsMap[type].notVoted
            }
          />
        }
        _hover={{bg: isDisabled ? 'transparent' : 'primary.50'}}
        borderRadius='full'
        colorScheme='primary'
        isDisabled={isDisabled}
        onPressOut={handleVote}
        zIndex='1'
        opacity={isLoading ? 0.4 : 1}
      />
      {showNumber && votes?.length ? <Text>{votes.length}</Text> : <></>}
    </Column>
  )
}

const VoteTypeKeyMap: Record<
  VoteType,
  keyof Pick<TEvaluation, 'upVotes' | 'downVotes' | 'flags'>
> = {
  up: 'upVotes',
  down: 'downVotes',
  flag: 'flags'
}

const VoteTypeIconsMap: Record<
  VoteType,
  Record<'voted' | 'notVoted', keyof typeof FontAwesome.glyphMap>
> = {
  up: {voted: 'thumbs-up', notVoted: 'thumbs-o-up'},
  down: {voted: 'thumbs-down', notVoted: 'thumbs-o-down'},
  flag: {voted: 'flag', notVoted: 'flag-o'}
}

function votedByUser(votes: TVote[] | undefined, id: string) {
  return votes?.some(vote => vote.id === id)
}
