import {Ionicons} from '@expo/vector-icons'
import {ImageInfo} from 'expo-image-picker'
import {isEqual} from 'lodash'
import {
  Button,
  Column,
  Modal,
  Icon,
  IconButton,
  Row,
  Heading
} from 'native-base'
import React, {useCallback, useEffect, useState} from 'react'

import {MAX_FILE_SIZE_MB} from '../../constants/general'
import LOCAL from '../../misc/localisation'
import useQueryUser from '../../services/useQueryUser'
import {updateUser, uploadPortrait} from '../../services/user'
import {TNotificationSettings, TUser} from '../../types/types'
import Logger from '../../utils/Logger'
import {FilePickCancelled, pickImage} from '../../utils/deviceUtils'
import NotificationSettings from './NotificationSettings'
import Portrait from './Portrait'

export default function Settings() {
  const [showModal, setShowModal] = useState(false)
  const [isPending, setPending] = useState(false)

  const {data: originalData, isLoading, refetch} = useQueryUser()

  const [user, setUser] = useState<TUser | undefined>()
  const updateNotifications = useCallback(
    (notifications: TNotificationSettings) =>
      setUser(user =>
        user ? {...user, settings: {...user.settings, notifications}} : user
      ),

    []
  )

  useEffect(() => {
    if (originalData) {
      setUser(originalData)
    }
  }, [originalData])

  const [image, setImage] = useState<ImageInfo | null>(null)
  const updatePortrait = useCallback(() => {
    if (!user) {
      return
    }
    pickImage({allowsEditing: true, maxFileSizeMb: MAX_FILE_SIZE_MB})
      .then(res => {
        setImage(res)
        uploadPortrait(user.id, res.uri).then(() => refetch())
      })
      .catch(err => {
        if (err instanceof FilePickCancelled) {
          console.debug(err.message)
        } else {
          console.error(err)
        }
      })
  }, [refetch, user])

  const name = `${user?.firstName} ${user?.lastName}`

  const submitUser = useCallback(() => {
    if (!user) return

    setPending(true)
    updateUser(user.id, user)
      .catch(err => Logger.debug('Error updating user', err))
      .finally(() => {
        refetch()
        setPending(false)
      })
  }, [refetch, user])

  const hasChanges = !isEqual(user, originalData)

  return (
    <>
      <IconButton
        onPress={() => setShowModal(true)}
        borderRadius='full'
        icon={<Icon as={Ionicons} name='settings-outline' size='sm' />}
        isPressed={showModal}
        _hover={{
          bg: 'coolGray.800:alpha.20'
        }}
        _pressed={{
          bg: 'coolGray.800:alpha.20'
        }}
      />
      {showModal && (
        <Modal
          isOpen={showModal}
          onClose={() => {
            !isLoading && setShowModal(false)
          }}
          size='full'
          animationPreset='slide'
        >
          <Modal.Content
            maxWidth='600'
            height='full'
            maxHeight='full'
            borderRadius='none'
          >
            <Modal.CloseButton />
            <Modal.Header>{LOCAL.SETTINGS.SETTINGS}</Modal.Header>
            <Modal.Body>
              {!user ? (
                <></>
              ) : (
                <Column space='2xl' w='full'>
                  <Row space='4' alignItems='center'>
                    <Portrait
                      uri={image?.uri ?? user?.portrait}
                      name={name}
                      onPick={updatePortrait}
                    />
                    <Heading size='sm'>{name}</Heading>
                  </Row>
                  <NotificationSettings
                    settings={user?.settings?.notifications}
                    update={updateNotifications}
                  />
                </Column>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button
                  isDisabled={isLoading}
                  variant='ghost'
                  colorScheme='blueGray'
                  onPress={() => {
                    setShowModal(false)
                  }}
                >
                  {LOCAL.CANCEL}
                </Button>
                <Button
                  isLoading={isLoading}
                  isDisabled={!hasChanges || isLoading || isPending}
                  onPress={submitUser}
                >
                  {isLoading || isPending ? LOCAL.PROCESSING : LOCAL.SAVE}
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
    </>
  )
}
