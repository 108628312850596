import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID
} from '@env'
import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getFunctions} from 'firebase/functions'
import {getStorage} from 'firebase/storage'

import Logger from '../utils/Logger'

Logger.debug('Firebase project id is:', FIREBASE_PROJECT_ID)

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const db = getFirestore()
const authentication = getAuth()
const storage = getStorage()
const functions = getFunctions(app, 'europe-west3')

export {db, authentication, storage, functions}
