import {Ionicons} from '@expo/vector-icons'
import {
  Button,
  Column,
  Heading,
  Icon,
  IconButton,
  Input,
  Row,
  ScrollView,
  StyledProps,
  Text,
  TextArea
} from 'native-base'
import React, {Dispatch, SetStateAction, useState} from 'react'
import {GestureResponderEvent} from 'react-native'

import {dimensionOrder} from '../../constants/dimensions'
import LOCAL from '../../misc/localisation'
import {TEvaluationDraft} from '../../services/evaluation'
import {TEvaluation} from '../../types/types'
import {Dimension} from './Dimension'

export default function EditEvaluation({
  speaker,
  title,
  evaluation,
  setEvaluation,
  handleSubmit
}: {
  speaker: string
  title: string
  evaluation: TEvaluationDraft
  setEvaluation: Dispatch<SetStateAction<TEvaluationDraft>>
  handleSubmit: () => any
}) {
  const [stage, setStage] = useState(1)
  const goForward = () => {
    if (stage < numberOfStages) {
      stage < numberOfStages && setStage(stage + 1)
    }
  }
  const goBack = () => {
    stage > 1 && setStage(stage - 1)
  }

  const handleChange =
    (key: Extract<keyof TEvaluation, 'praise' | 'suggestions' | 'title'>) =>
    (text: string) =>
      setEvaluation({...evaluation, [key]: text})

  const setDimensionValue = (dimension: string, value: number) => {
    setEvaluation({
      ...evaluation,
      dimensions: {...evaluation.dimensions, [dimension]: value}
    })
  }

  const firstName = speaker.split(' ')[0]

  const stages: Record<number, boolean> = {
    1: Boolean(evaluation.praise.length),
    2: Boolean(evaluation.suggestions.length),
    3: true
  }

  const numberOfStages = Object.keys(stages).length

  return (
    <ScrollView p='4' h='full'>
      <Column h='full' space='2'>
        <Text fontSize='sm' mb='2' textAlign='center'>
          {LOCAL.EVALUATION.EVALUATE_SPEACH_FROM(speaker, title)}
        </Text>
        {stage === 1 && (
          <Column space='lg'>
            <EditText
              headline={LOCAL.EVALUATION.TITLE}
              text={evaluation.title}
              handleChange={handleChange('title')}
            />
            <EditTextArea
              headline={LOCAL.EVALUATION.PRAISE}
              text={evaluation.praise}
              handleChange={handleChange('praise')}
            />
          </Column>
        )}
        {stage === 2 && (
          <EditTextArea
            headline={LOCAL.EVALUATION.SUGGESTIONS(firstName)}
            text={evaluation.suggestions}
            handleChange={handleChange('suggestions')}
          />
        )}
        {stage === 3 && (
          <EditDimensions
            heading={LOCAL.EVALUATION.EVALUE_DIMENSIONS_FROM(firstName)}
            evaluation={evaluation}
            setDimensionValue={setDimensionValue}
            handleSubmit={handleSubmit}
          />
        )}
        <Navigation
          stage={stage}
          stages={stages}
          goBack={goBack}
          goForward={goForward}
          my={2}
        />
      </Column>
    </ScrollView>
  )
}

function EditText({
  headline,
  text,
  handleChange
}: {
  headline: string
  text: string
  handleChange: (text: string) => void
}) {
  return (
    <>
      <Heading fontSize='2xl' bold mb='6' textAlign='center'>
        {headline}
      </Heading>
      <Input placeholder={headline} value={text} onChangeText={handleChange} />
    </>
  )
}

function EditTextArea({
  headline,
  text,
  handleChange
}: {
  headline: string
  text: string
  handleChange: (text: string) => void
}) {
  return (
    <>
      <Heading fontSize='2xl' bold mb='6' textAlign='center'>
        {headline}
      </Heading>
      <TextArea
        placeholder={headline}
        value={text}
        onChangeText={handleChange}
        mb='2'
        minH='120'
      />
    </>
  )
}

function EditDimensions({
  heading,
  evaluation,
  handleSubmit,
  setDimensionValue
}: {
  heading: string
  handleSubmit: (event: GestureResponderEvent) => void
  evaluation: TEvaluationDraft
  setDimensionValue: (dimension: string, value: number) => void
}) {
  return (
    <>
      <Heading fontSize='2xl' bold mb='6' textAlign='center'>
        {heading}
      </Heading>
      {dimensionOrder.map(dimension => (
        <Dimension
          dimension={dimension}
          dimensionValue={evaluation.dimensions[dimension]}
          setDimensionValue={setDimensionValue}
          key={dimension}
        />
      ))}
      <Button my='4' onPress={handleSubmit}>
        {LOCAL.SUBMIT}
      </Button>
    </>
  )
}

function Navigation({
  stages,
  stage,
  goBack,
  goForward,
  ...props
}: {
  stages: Record<number, boolean>
  stage: number
  goBack: (event: GestureResponderEvent) => void
  goForward: (event: GestureResponderEvent) => void
} & StyledProps) {
  const numberOfStages = Object.keys(stages).length
  const isCurrentStageComplete = stages[stage]
  const isFirstStage = stage === 1
  const isLastStage = stage === numberOfStages

  return (
    <Row {...props} alignSelf='center' alignItems='center' space='1'>
      {!isFirstStage && (
        <IconButton
          icon={<Icon as={Ionicons} name='chevron-back-outline' />}
          borderRadius='full'
          colorScheme='light'
          onPress={goBack}
        />
      )}
      {Object.entries(stages).map(([_stage]) => (
        <Icon
          as={Ionicons}
          key={_stage}
          size='sm'
          _light={{color: 'primary.500'}}
          _dark={{color: 'primary.500'}}
          name={Number(_stage) <= stage ? 'ellipse' : 'ellipse-outline'}
        />
      ))}
      {!isLastStage && (
        <IconButton
          icon={<Icon as={Ionicons} name='chevron-forward-outline' />}
          borderRadius='full'
          colorScheme='light'
          opacity={isCurrentStageComplete ? 1 : 0.4}
          onPress={isCurrentStageComplete ? goForward : undefined}
        />
      )}
    </Row>
  )
}
