import {Box, IImageProps, Image} from 'native-base'
import React from 'react'

export default function Thumbnail({
  uri,
  alt,
  ...props
}: {uri: string | undefined} & IImageProps) {
  return uri ? (
    <Image h='full' w='full' alt={alt} source={{uri}} {...props} />
  ) : (
    <Box
      bg={{
        linearGradient: {
          colors: ['red.600', 'blue.600'],
          start: [0, 0],
          end: [1, 1]
        }
      }}
      position='absolute'
      top='0'
      left='0'
      right='0'
      bottom='0'
    />
  )
}
