import React, {useCallback, useState} from 'react'

import {patchEvaluation} from '../../services/evaluation'
import {TEvaluation, TSpeech} from '../../types/types'
import EditEvaluation from './EditEvaluation'
import {initialEvaluation} from './SubmitEvaluation'

export default function PatchEvaluation({
  speech: {speaker, title},
  closeModal,
  refetch,
  currentEvaluation
}: {
  speech: TSpeech
  closeModal: () => void
  refetch: () => any
  currentEvaluation: TEvaluation
}) {
  const [evaluation, setEvaluation] = useState({
    title: currentEvaluation.title ?? '',
    praise: currentEvaluation.praise,
    suggestions: currentEvaluation.suggestions,
    dimensions: currentEvaluation.dimensions
      ? currentEvaluation.dimensions
      : initialEvaluation.dimensions
  })

  const handleSubmit = useCallback(() => {
    patchEvaluation({id: currentEvaluation.id, ...evaluation}).then(() =>
      refetch()
    )
    closeModal()
  }, [closeModal, currentEvaluation.id, evaluation, refetch])

  const firstName = speaker.split(' ')[0]

  return (
    <EditEvaluation
      speaker={firstName}
      title={title}
      evaluation={evaluation}
      setEvaluation={setEvaluation}
      handleSubmit={handleSubmit}
    />
  )
}
