import {Badge, Toast, useColorModeValue} from 'native-base'
import React from 'react'

type TToastStatus = 'primary' | 'secondary' | 'success' | 'warning' | 'error'

type TShowToast = (text: string, status?: TToastStatus) => void

export default function useToast(): TShowToast {
  const bgColor = useColorModeValue('light.50', 'light.900')

  const showToast: TShowToast = (
    text: string,
    status: TToastStatus = 'success'
  ) => {
    Toast.show({
      render: () => {
        return (
          <Badge
            _text={{
              fontSize: 'md'
            }}
            colorScheme={status}
            variant='outline'
            bgColor={bgColor}
          >
            {text}
          </Badge>
        )
      }
    })
  }

  return showToast
}
