import {LinearGradient} from 'expo-linear-gradient'
import {INativebaseConfig} from 'native-base'

const config: INativebaseConfig = {
  strictMode: 'error',
  dependencies: {
    'linear-gradient': LinearGradient
  }
}

export default config
