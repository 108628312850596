import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  UserCredential
} from 'firebase/auth'
import {
  doc,
  FirestoreError,
  getDoc,
  setDoc,
  updateDoc
} from 'firebase/firestore'

import {TUser} from '../types/types'
import Logger from '../utils/Logger'
import {uploadImageAsync} from './file'
import {authentication, db} from './firebase'

export {
  addUser,
  getUser,
  checkEmail,
  resetPassword,
  signInUser,
  signUpUser,
  signOut,
  updateUser,
  uploadPortrait
}

function signInUser({
  email,
  password
}: {
  email: string
  password: string
}): Promise<UserCredential | Error> {
  return signInWithEmailAndPassword(authentication, email, password)
}

function signUpUser({
  email,
  password
}: {
  email: string
  password: string
}): Promise<UserCredential | Error> {
  return createUserWithEmailAndPassword(authentication, email, password)
}

function checkEmail(email: string): Promise<string[]> {
  return fetchSignInMethodsForEmail(authentication, email)
}

function resetPassword(email: string): Promise<void> {
  return sendPasswordResetEmail(authentication, email)
}

function signOut(): Promise<void> {
  return authentication.signOut()
}

function getUser(id: string): Promise<TUser> {
  const ref = doc(db, 'users', id)
  return getDoc(ref)
    .then(doc => {
      if (doc.exists()) {
        Logger.debug('User document exists.')
        const data = doc.data()
        if (data) {
          Logger.debug('User data exists.')
          return data as TUser
        } else {
          throw new Error('Document is undefined.')
        }
      } else throw new Error('Document does not exist.')
    })
    .catch((error: FirestoreError) => {
      Logger.error(error)
      throw error
    })
}

function addUser({
  firstName,
  lastName,
  user
}: {firstName: string; lastName: string} & UserCredential): void {
  if (user) {
    setDoc(doc(db, 'users', user.uid), {
      firstName,
      lastName,
      id: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
      metadata: {
        ...user.metadata
      },
      providerId: user.providerId
    })
      .then(() => Logger.debug('User successfully added to db.'))
      .catch(error => Logger.error(error))
  } else {
    Logger.error(new Error('Cannot add user to db. User is null.'))
  }
}

function updateUser(id: string, user: Partial<TUser>): Promise<void> {
  return updateDoc(doc(db, 'users', id), user)
    .then(() => Logger.debug('User successfully updated user in db.'))
    .catch(error => Logger.error('Error updating user. ', error))
}

async function uploadPortrait(userId: string, uri: string): Promise<void> {
  const path = `images/portraits/${userId}`

  return uploadImageAsync(path, uri).then(url =>
    updateUser(userId, {portrait: url})
  )
}
