import {
  requestMediaLibraryPermissionsAsync,
  ImagePickerOptions,
  ImageInfo,
  launchImageLibraryAsync,
  MediaTypeOptions
} from 'expo-image-picker'
import {Platform} from 'react-native'

import {ErrorCodes} from '../misc/ErrorCodes'
import {verifyMaxFileSize} from './fileUtils'

async function getMediaPermission(): Promise<boolean> {
  return new Promise(async resolve => {
    if (Platform.OS === 'web') {
      return resolve(true)
    }

    resolve((await requestMediaLibraryPermissionsAsync()).granted)
  })
}

export class FilePickCancelled extends Error {
  constructor() {
    super()
    this.name = ErrorCodes.FilePickCancelled
    this.message = 'User cancelled image selection.'
  }
}

export async function pickImage(
  options?: ImagePickerOptions & {maxFileSizeMb?: number}
): Promise<ImageInfo> {
  return new Promise(async (resolve, reject) => {
    const permitted = await getMediaPermission()
    if (!permitted) {
      reject(
        new Error('You need to allow access to your photos to upload an image.')
      )
    }
    console.debug('Received media permission.')
    const pickerResult = await launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      ...options
    })

    if (pickerResult.cancelled) {
      reject(new FilePickCancelled())
    }
    console.debug('Image has been picked.')

    if (options?.maxFileSizeMb) {
      if (
        !(await verifyMaxFileSize(
          (pickerResult as ImageInfo).uri,
          options.maxFileSizeMb
        ))
      ) {
        reject(
          new Error(`File is to big. Maximum is ${options.maxFileSizeMb}mb.`)
        )
      }
    }
    resolve(pickerResult as ImageInfo)
  })
}
