import {useMachine} from '@xstate/react'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {createMachine, State} from 'xstate'

import {authentication} from '../services/firebase'
import {resetUser, setUser} from '../store/reducer/userSlice'
import Logger from '../utils/Logger'

export default function useAuthentication(): State<
  any,
  any,
  any,
  {
    value: any
    context: any
  }
> {
  const dispatch = useDispatch()
  const [state, send] = useMachine(authStateMachine)
  useEffect(() => {
    const unsubscribeFromAuthentication = authentication.onAuthStateChanged(
      async user => {
        if (user) {
          Logger.debug('User signed in: ', user.email)
          send(AuthActions.SIGNED_IN)
          dispatch(setUser({loggedIn: true, uid: user.uid}))
        } else {
          Logger.debug('User is not signed in or just signed out.')
          dispatch(resetUser())
          send(AuthActions.SIGNED_OUT)
        }
      }
    )
    return () => {
      Logger.debug('unsubscribeFromAuthentication')
      unsubscribeFromAuthentication()
    }
  }, [dispatch, send])

  return state
}

export enum AuthState {
  Unknown = 'Unknown',
  SignedIn = 'SignedIn',
  NotSignedIn = 'NotSignedIn'
}

enum AuthActions {
  SIGNED_IN = 'SignedIn',
  SIGNED_OUT = 'NotSignedIn'
}

const authStateMachine = createMachine({
  id: 'auth',
  initial: AuthState.Unknown,
  states: {
    [AuthState.Unknown]: {
      on: {
        [AuthActions.SIGNED_IN]: AuthState.SignedIn,
        [AuthActions.SIGNED_OUT]: AuthState.NotSignedIn
      }
    },
    [AuthState.SignedIn]: {
      on: {
        [AuthActions.SIGNED_OUT]: AuthState.NotSignedIn
      }
    },
    [AuthState.NotSignedIn]: {
      on: {
        [AuthActions.SIGNED_IN]: AuthState.SignedIn
      }
    }
  }
})
