import {useEffect} from 'react'
import {useQuery} from 'react-query'

import {QueryKeys} from '../services/QueryKeys'
import {getContentSettings} from '../services/settings'
import {TSpeech, getSpeech} from '../services/speech'

const speechQueryKeyMap: Record<TSpecialSpeech, QueryKeys> = {
  prime: QueryKeys.PrimeSpeech,
  focus: QueryKeys.FocusSpeech
}

type TSpecialSpeech = 'prime' | 'focus'

export default function useSpeech(type: TSpecialSpeech): {
  speech: TSpeech | undefined
  isLoading: boolean
} {
  const {data} = useQuery(QueryKeys.ContentSettings, getContentSettings)

  const id = data?.stage[type]

  const {
    data: speech,
    isLoading,
    refetch
  } = useQuery(speechQueryKeyMap[type], () => getSpeech(id ?? ''), {
    enabled: false
  })

  useEffect(() => {
    if (id && !speech) {
      refetch()
    }
  }, [id, refetch, speech])

  return {speech, isLoading}
}
