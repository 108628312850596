import {Ionicons} from '@expo/vector-icons'
import {useNavigation, RouteProp} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'
import {
  Column,
  Container,
  Heading,
  Icon,
  IconButton,
  Row,
  ScrollView,
  Text
} from 'native-base'
import React, {useCallback, useEffect} from 'react'
import {GestureResponderEvent, StyleSheet} from 'react-native'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'

import EvaluationButton from '../components/evaluation/EvaluationButton'
import Evaluations from '../components/evaluation/Evaluations'
import SubmitEvaluation from '../components/evaluation/SubmitEvaluation'
import BaseModal from '../components/ui/BaseModal'
import LabeledIcon from '../components/ui/LabeledIcon'
import Page from '../components/ui/Page'
import VideoPlayer from '../components/ui/VideoPlayer'
import useModal from '../hooks/useModal'
import usePreviewImage from '../hooks/usePreviewImage'
import LOCAL from '../misc/localisation'
import {StageParamList} from '../navigation/types'
import {QueryKeys} from '../services/QueryKeys'
import {countSpeechView, getSpeech} from '../services/speech'
import {selectUserId} from '../store/reducer/userSlice'

enum SpeechCategoryIcon {
  comedy = 'laugh-beam',
  biography = 'user-circle',
  pitch = 'lightbulb',
  science = 'microscope',
  lecture = 'book'
}

export default function SpeechScreen({
  route
}: {
  route: RouteProp<{Speech: {id: string}}, 'Speech'>
}) {
  const navigation = useNavigation<StackNavigationProp<StageParamList>>()
  const {id} = route.params

  const userId = useSelector(selectUserId)
  const {isOpen, closeModal, showModal} = useModal()

  const {
    isLoading,
    error,
    data: speech
  } = useQuery([QueryKeys.Speech, id], () => getSpeech(id))

  useEffect(() => {
    countSpeechView(id)
  }, [id])

  const thumbnail = usePreviewImage(speech)

  const speakerIsUser = userId === speech?.userId
  const renderEvaluationModal = !speakerIsUser && !isLoading && speech

  const handleBack = useCallback(() => {
    navigation.replace('Overview')
  }, [navigation])

  return (
    <Page>
      <ScrollView showsVerticalScrollIndicator={false}>
        <IconButton
          _icon={{size: 'sm'}}
          icon={<Icon as={Ionicons} name='arrow-back' />}
          onPress={handleBack}
          position='absolute'
          borderRadius='full'
          top='2'
          left='2'
          zIndex='1'
          colorScheme='light'
          accessibilityLabel={LOCAL.NAVIGATION.BACK}
        />
        <Container
          alignSelf='center'
          w='full'
          h='full'
          maxW='1200'
          position='relative'
        >
          {error && <Text>{LOCAL.SOMETHING_WENT_WRONG}</Text>}
          {!isLoading && speech ? (
            <Column w='full' maxW='1200' alignSelf='center'>
              {speech.file.url && (
                <VideoPlayer
                  posterSource={{uri: thumbnail}}
                  style={styles.player}
                  uri={speech.file.url}
                />
              )}
              <Column p='4' space='4'>
                <Row py='2' justifyContent='space-between'>
                  <Row space='2'>
                    <LabeledIcon
                      label={speech.category}
                      name={SpeechCategoryIcon[speech.category]}
                    />
                    {/* <LabeledNumber label={LOCAL.SPEECH.VIEWS} number={'1257'} /> */}
                    {/* <LabeledNumber label={__.SPEECH.EVALUATIONS} number={'12'} /> */}
                  </Row>
                  <Row space='2'>
                    {!speakerIsUser && (
                      <EvaluationButton
                        onPress={
                          showModal as (event: GestureResponderEvent) => void
                        }
                        id={id}
                      />
                    )}
                    {/* <LabeledIcon
                    label={__.SOCIAL.SHARE}
                    size={32}
                    name='share'
                  /> */}
                  </Row>
                </Row>
                <Column space='1'>
                  <Text>{speech.speaker}</Text>
                  <Row
                    mb='2'
                    justifyContent='space-between'
                    alignItems='flex-end'
                  >
                    <Heading>{speech.title}</Heading>
                    <Text fontSize='sm'>{speech.duration}</Text>
                  </Row>
                  <Text>{speech.description}</Text>
                </Column>
                <Evaluations id={id} speech={speech} />
              </Column>
            </Column>
          ) : (
            <Text>{LOCAL.LOADING}</Text>
          )}
          {renderEvaluationModal ? (
            <BaseModal isOpen={isOpen} closeModal={closeModal}>
              <SubmitEvaluation speech={speech} closeModal={closeModal} />
            </BaseModal>
          ) : (
            <></>
          )}
        </Container>
      </ScrollView>
    </Page>
  )
}

const styles = StyleSheet.create({
  player: {
    minHeight: 200,
    maxWidth: 1200,
    maxHeight: 600,
    height: 'auto',
    resizeMode: 'contain',
    backgroundColor: '#000'
  }
})
