import React from 'react'

import useSpeech from '../../hooks/useSpeech'
import SpeechCard from './SpeechCard'

export default function FocusSpeech() {
  const {speech} = useSpeech('focus')

  return speech ? (
    <SpeechCard
      speech={speech}
      w='full'
      h={['200', '300']}
      px='6'
      resizeMode='center'
    />
  ) : (
    <></>
  )
}
