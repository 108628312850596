import {getThumbnailAsync} from 'expo-video-thumbnails'
import {useEffect, useState} from 'react'
import {Platform} from 'react-native'

import {TSpeech} from '../types/types'
import Logger from '../utils/Logger'

export default function usePreviewImage(
  speech: TSpeech | undefined
): string | undefined {
  const [image, setImage] = useState<string | undefined>()
  useEffect(() => {
    if (!speech) return
    const uri = speech.thumbnails?.find(thumbnail => thumbnail.preview)?.url

    if (uri) {
      setImage(uri)
    } else {
      generateThumbnail(speech.file.url)
        .then(uri => setImage(uri))
        // TODO: Upload thumbnail to server.
        .catch(e => Logger.error(e))
    }
  }, [speech])

  return image
}

const generateThumbnail = async (url: string | undefined): Promise<string> => {
  if (!url) throw new Error('No url given.')
  if (Platform.OS === 'web')
    throw new Error(
      'Generating a thumbnail from a video is not supported on web.'
    )
  const {uri} = await getThumbnailAsync(url, {
    time: 15000
  })
  return uri
}
