import {ReactNode} from 'react'

import ThemedBox from './ThemedBox'

export default function Page({children}: {children: ReactNode}) {
  return (
    <ThemedBox h='100%' w='100%' safeArea>
      {children}
    </ThemedBox>
  )
}
