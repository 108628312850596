import {createAction} from '@reduxjs/toolkit'
import {FirestoreError} from 'firebase/firestore'
import {
  getDownloadURL,
  StorageError,
  TaskState,
  UploadTaskSnapshot
} from 'firebase/storage'
import {Middleware} from 'redux'

import {ErrorCodes} from '../../misc/ErrorCodes'
import {
  deleteSpeech,
  patchSpeech,
  postSpeech,
  uploadSpeech,
  uploadThumbnails
} from '../../services/speech'
import {TSpeechCategory, TUser} from '../../types/types'
import Logger from '../../utils/Logger'
import {
  changeState,
  FormStates,
  TUploadSpeechForm,
  updateForm,
  updateUploadProgress
} from '../reducer/uploadSpeechSlice'

type TUploadSpeechActionPayload = {
  user: TUser
  callback: Function | undefined
}

export const uploadSpeechAction =
  createAction<TUploadSpeechActionPayload>('uploadSpeech')

export const uploadSpeechMiddleware: Middleware<object, any> =
  store => next => async action => {
    const {type, payload} = action
    switch (type) {
      case uploadSpeechAction.type:
        {
          // TODO: Fix state type
          const {user, callback} = payload as TUploadSpeechActionPayload
          store.dispatch(changeState(FormStates.Uploading))
          Logger.debug('Uploading speech')
          const form = store.getState().uploadSpeech as TUploadSpeechForm
          const {upload} = form

          if (form.file.type !== 'success') {
            store.dispatch(changeState(FormStates.Error))
            return
          }

          const file = {
            name: form.file.name,
            size: form.file.size,
            lastModified: form.file.lastModified ?? null
          }

          const id = await postSpeech({
            duration: form.file.duration,
            title: form.title,
            description: form.description,
            category: form.category as TSpeechCategory,
            language: form.language,
            country: form.country,
            file,
            uploadStatus: 'uploading',
            userId: user.id,
            speaker: `${user.firstName} ${user.lastName}`
          })
          if (!id) {
            Logger.debug('Could not post speech.')
            store.dispatch(changeState(FormStates.Error))
            return
          }
          Logger.debug('Speech posted.')
          store.dispatch(updateForm({id}))

          const data = await getBlobFromUri(form.file.uri)

          if (!(data instanceof Blob)) {
            Logger.error('Could not get file from uri', data)

            deleteSpeech(id)
            store.dispatch(changeState(FormStates.Editing))
            return
          }

          try {
            Logger.debug('Uploading thumbnails ...')
            // TODO: Thumbnails should not be uploaded, but instead created server side, according to the timestamps set by the user.
            const downloadUrls = await uploadThumbnails({
              thumbnails: form.thumbnails,
              id
            })
            const thumbnails = form.thumbnails.map((thumbnail, index) => ({
              ...thumbnail,
              url: downloadUrls[index]
            }))
            patchSpeech(id, {
              thumbnails
            })
              .then(() => Logger.debug('Speech patched with thumbnails.'))
              .catch(error =>
                Logger.error('Error patching speech with thumbnails.', error)
              )

            Logger.debug('Thumbnails uploaded: ...')
          } catch (error) {
            store.dispatch(changeState(FormStates.Error))
            Logger.error('Error uploading thumbnails', error)
          }

          const uploadTask = uploadSpeech({
            file: data,
            id,
            filename: form.file.name
          })

          Logger.debug('Started upload of file ', file.name)

          uploadTask.on(
            'state_changed',
            (snapshot: UploadTaskSnapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress: number = Number(
                (
                  (snapshot.bytesTransferred / snapshot.totalBytes) *
                  100
                ).toFixed(0)
              )
              if (upload.progress !== progress) {
                Logger.debug('Upload is ' + progress + '% done')
                store.dispatch(updateUploadProgress(progress))
              }
              // eslint-disable-next-line default-case
              switch (snapshot.state) {
                case 'paused' as TaskState: // or 'paused'
                  Logger.debug('Upload is paused')
                  break
                case 'running' as TaskState: // or 'running'
                  Logger.debug('Upload is running')
                  break
              }
            },
            (error: StorageError) => {
              // Handle unsuccessful uploads

              switch (error.code) {
                case ErrorCodes.UploadCancelled:
                  Logger.debug('User cancelled upload')
                  // TODO: Delete speech or show another interface to just upload file? Maybe for now delete it.
                  deleteSpeech(id)
                  store.dispatch(changeState(FormStates.Editing))
                  break

                case ErrorCodes.StorageUnauthorized:
                  Logger.error(
                    'User is not authorized to upload to this bucket.'
                  )
                  deleteSpeech(id)
                  store.dispatch(changeState(FormStates.Error))
                  break

                default:
                  Logger.error('Error uploading:', error)
                  patchSpeech(id, {
                    uploadStatus: 'error'
                  })
                  store.dispatch(changeState(FormStates.Error))
                  break
              }
            },
            async () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              const ref = uploadTask.snapshot.ref

              const url = await getDownloadURL(ref)

              Logger.debug('Video successfully uploaded. Id: ', id, 'URL:', url)

              store.dispatch(changeState(FormStates.Uploaded))

              patchSpeech(id, {
                uploadStatus: 'uploaded',
                file: {...file, url, path: ref.fullPath}
              })
                .then((_: any) => {
                  Logger.debug(`Speech updated with file url: ${url}`)
                  callback?.()
                })
                .catch((error: FirestoreError) => {
                  Logger.error(error)
                  store.dispatch(changeState(FormStates.Error))
                })
            }
          )
        }
        break
    }
    return next(action)
  }

async function getBlobFromUri(uri: string): Promise<Blob> {
  return (await fetch(uri)).blob()
}
