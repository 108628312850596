import {ITextProps, Pressable, Text} from 'native-base'
import React, {useState} from 'react'

export default function TruncatedText({
  length = 400,
  children,
  ...props
}: {length?: number; children: string} & ITextProps) {
  const [isTruncated, setTruncated] = useState(children.length > length)

  const TextComponent = () => (
    <Text {...props}>
      {isTruncated ? children.slice(0, length) + ' ...' : children}
    </Text>
  )

  return isTruncated ? (
    <Pressable onPress={() => setTruncated(false)}>
      <TextComponent />
    </Pressable>
  ) : (
    <TextComponent />
  )
}
